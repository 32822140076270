import styled from "styled-components";

export const PricesWrapper = styled.div`
  padding: 20px 100px;

  @media (max-width: 600px) {
    padding: 20px;
  }
`;

export const OneMonthInfo = styled.p`
  margin: 25px 0;
  display: flex;
  justify-content: center;
  gap: 10px;
  color: #4f9b5a;
  font-style: italic;
`;
