import { createBrowserRouter } from "react-router-dom";
import Logged from "../layouts/Logged";
import Unlogged from "../layouts/Unlogged";
import Home from "../views/Home";
import ContactUs from "../views/ContactUs";
import BookService from "../views/BookService";
import LoggedBookService from "../views/LoggedBookService";
import BookingHistory from "../views/BookingHistory";
import Basket from "../views/Basket";
import Prices from "../views/Prices";
import PaymentsHistory from "../views/PaymentsHistory";
import TermsAndConditions from "../views/TermsAndConditions";
import Services from "../views/Services";
import Profile from "../views/Profile";
import Invoice from "../views/Invoice";

export const unloggedRouter = createBrowserRouter([
  {
    path: "/",
    element: <Unlogged />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "services",
        element: <Services />,
      },
      {
        path: "pricing",
        element: <Prices />,
      },
      {
        path: "contact-us",
        element: <ContactUs />,
      },
      {
        path: "book-service",
        element: <BookService />,
      },
      {
        path: "terms-and-conditions",
        element: <TermsAndConditions />,
      },
      {
        path: "*",
        element: <></>,
      },
    ],
  },
]);

export const loggedRouter = createBrowserRouter([
  {
    path: "/",
    element: <Logged />,
    children: [
      {
        path: "",
        element: <LoggedBookService />,
      },
      {
        path: "booking-history",
        element: <BookingHistory />,
      },
      {
        path: "services",
        element: <Services />,
      },
      {
        path: "basket",
        element: <Basket />,
      },
      {
        path: "contact-us",
        element: <ContactUs />,
      },
      {
        path: "payments-history",
        element: <PaymentsHistory />,
      },
      {
        path: "payments-history/invoice/:id",
        element: <Invoice />,
      },
      {
        path: "terms-and-conditions",
        element: <TermsAndConditions />,
      },
      {
        path: "pricing",
        element: <Prices />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "*",
        element: <></>,
      },
    ],
  },
]);
