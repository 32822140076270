import styled from "styled-components";

export const ContactForm = styled.form`
  display: grid;
  justify-content: center;
`;

export const SendedInfo = styled.p`
  font-size: 18px;
  margin: 25px 0;
  font-size: 18px;
  margin: 25px 0;
  color: #4f9b5a;
  font-style: italic;
  display: flex;
  align-items: center;
  gap: 10px;
`;
