import { Formik } from "formik";
import { registerFormElements } from "../../atoms/formElements/formElementsData";
import FormElement from "../../atoms/FormElement";
import Button from "../../atoms/Button";
import { registerFormValidationSchema } from "../../../validation/validationSchema";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, usersCollection } from "../../../firebase";
import { addDoc } from "firebase/firestore";
import { useModal } from "../../../contexts/Modal";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";
import { ErrorText } from "./StyledRegister";
import { BsFillExclamationTriangleFill } from "react-icons/bs";

interface FormFieldsInterface {
  firstName: string;
  surname: string;
  companyName: string;
  email: string;
  password?: string;
  contactNumber: string;
  confirmPassword?: string;
  acceptTerms?: boolean;
}

const Register = () => {
  const { closeModal } = useModal();

  const [reCaptchaToken, setReCaptchaToken] = useState<any>("");
  const [error, setError] = useState(false);

  const handleRegistration = async (values: FormFieldsInterface) => {
    if (!reCaptchaToken) {
      return;
    }

    try {
      const firebaseUser = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password as string
      );

      const dbUserObject = { ...values };
      delete dbUserObject.password;
      delete dbUserObject.confirmPassword;
      delete dbUserObject.acceptTerms;

      await addDoc(usersCollection, {
        id: firebaseUser.user.uid,
        ...dbUserObject,
      });

      closeModal();
    } catch (e) {
      setError(true);

      setTimeout(() => {
        setError(false);
      }, 5000);
    }
  };

  return (
    <Formik
      validationSchema={registerFormValidationSchema}
      onSubmit={handleRegistration}
      initialValues={{
        firstName: "",
        surname: "",
        companyName: "",
        email: "",
        password: "",
        confirmPassword: "",
        contactNumber: "",
        acceptTerms: false,
      }}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          {error && (
            <ErrorText>
              <BsFillExclamationTriangleFill />
              This email already exist{" "}
            </ErrorText>
          )}

          {registerFormElements.map((el, index) => (
            <>
              <FormElement
                key={index}
                inputName={el.inputName}
                inputType={el.inputType}
                labelContent={el.labelContent}
              />
            </>
          ))}
          <div style={{ marginBottom: "10px" }}>
            <ReCAPTCHA
              size="normal"
              sitekey={process.env.REACT_APP_CAPTCHA_TOKEN || ""}
              onChange={(value: any) => setReCaptchaToken(value)}
            />
          </div>
          <Button type="submit">Submit</Button>
        </form>
      )}
    </Formik>
  );
};

export default Register;
