import { PricesWrapper, OneMonthInfo } from "./StyledPrices";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  STANDARD_PRICE,
  EVENING_PRICE,
  WEEKEND_PRICE,
  FLEX_PRICE,
} from "../../data/prices";
import { BsFillInfoCircleFill } from "react-icons/bs";

const Prices = () => {
  return (
    <PricesWrapper>
      <OneMonthInfo>
        <BsFillInfoCircleFill />
        Please note that hours have to be used within one month from purchase
      </OneMonthInfo>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Package</TableCell>
              <TableCell>Flexible hour</TableCell>
              <TableCell>Standard price per hour</TableCell>
              <TableCell>Evening price per hour</TableCell>
              <TableCell>Weekend price per hour </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Single hours</TableCell>
              <TableCell>£{FLEX_PRICE} </TableCell>
              <TableCell>£{STANDARD_PRICE} </TableCell>
              <TableCell>£{EVENING_PRICE}</TableCell>
              <TableCell>£{WEEKEND_PRICE}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>More than 10 hours </TableCell>
              <TableCell>£{FLEX_PRICE} </TableCell>
              <TableCell>£{STANDARD_PRICE - 1} </TableCell>
              <TableCell>£{EVENING_PRICE - 1}</TableCell>
              <TableCell>£{WEEKEND_PRICE - 1}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>More than 20 hours</TableCell>
              <TableCell>£{FLEX_PRICE} </TableCell>
              <TableCell>£{STANDARD_PRICE - 2} </TableCell>
              <TableCell>£{EVENING_PRICE - 2}</TableCell>
              <TableCell>£{WEEKEND_PRICE - 2}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>More than 40 hours</TableCell>
              <TableCell>£{FLEX_PRICE} </TableCell>
              <TableCell>£{STANDARD_PRICE - 3} </TableCell>
              <TableCell>£{EVENING_PRICE - 3}</TableCell>
              <TableCell>£{WEEKEND_PRICE - 3}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>More than 60 hours</TableCell>
              <TableCell>£{FLEX_PRICE} </TableCell>
              <TableCell>£{STANDARD_PRICE - 4} </TableCell>
              <TableCell>£{EVENING_PRICE - 4}</TableCell>
              <TableCell>£{WEEKEND_PRICE - 4}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </PricesWrapper>
  );
};

export default Prices;
