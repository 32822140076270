import styled from "styled-components";

export const ErrorText = styled.p`
  color: red;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
  justify-content: flex-end;
`;
