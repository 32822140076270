import homeImage from "../../assets/images/homepage_background.jpg";
import PAImage from "../../assets/images/PA.jpg";
import BKandPAYImage from "../../assets/images/BK_PAY2.jpg";
import PAandAdminImage from "../../assets/images/PA_Admin.jpg";
import AdHocImage from "../../assets/images/diary.jpg";

import itSupport from "../../assets/images/it_support.jpg";
import bookkeeping from "../../assets/images/bookkiping.jpg";

import {
  HomeWrapper,
  HomeImage,
  HomeOfferItem,
  HomeOfferItemImage,
  HomeOfferItemTitle,
  ServicesWrapper,
  HomeHeader,
  TextWrapper,
} from "./StyledHome";

const Home = () => {
  return (
    <div>
      <HomeWrapper>
        <HomeImage src={PAImage} />
        <div>
          <HomeHeader>
            Ready to bring balance to your working life? Hire a UK-based Virtual
            Assistant!
          </HomeHeader>

          <TextWrapper>
            Virtual business assistants work remotely on a contract basis to
            handle tasks on your behalf.
          </TextWrapper>
          <TextWrapper>
            Hiring a VA service is a great way for you to free up valuable time
            to build and grow your business.
          </TextWrapper>
          <TextWrapper>
            We offer flexible and scalable service plans to suit your business
            needs.
          </TextWrapper>
          <TextWrapper>
            All of our Virtual Business Assistants have several years of
            experience in UK blue chip companies and have developed transferable
            skills to support you as you grow your business.
          </TextWrapper>
        </div>
      </HomeWrapper>
      <ServicesWrapper>
        <HomeOfferItem>
          <HomeOfferItemImage src={bookkeeping} />
          <HomeOfferItemTitle to="/services">Bookkeeping</HomeOfferItemTitle>
        </HomeOfferItem>
        <HomeOfferItem>
          <HomeOfferItemImage src={BKandPAYImage} />

          <HomeOfferItemTitle to="/services">Payroll</HomeOfferItemTitle>
        </HomeOfferItem>

        <HomeOfferItem>
          <HomeOfferItemImage src={PAandAdminImage} />
          <HomeOfferItemTitle to="/services">
            PA & Administration
          </HomeOfferItemTitle>
        </HomeOfferItem>

        <HomeOfferItem>
          <HomeOfferItemImage src={homeImage} />
          <HomeOfferItemTitle to="/services">HR Management</HomeOfferItemTitle>
        </HomeOfferItem>

        <HomeOfferItem>
          <HomeOfferItemImage src={itSupport} />
          <HomeOfferItemTitle to="/services">IT Support</HomeOfferItemTitle>
        </HomeOfferItem>
        <HomeOfferItem>
          <HomeOfferItemImage src={AdHocImage} />
          <HomeOfferItemTitle to="/services">Ad-hoc support</HomeOfferItemTitle>
        </HomeOfferItem>
      </ServicesWrapper>
    </div>
  );
};

export default Home;
