import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "../Button";
import { useModal } from "../../../contexts/Modal";
import {
  InfoText,
  LineThroughtItem,
  ButtonsWrapper,
} from "./StyledNotFreeHoursModal";

interface PropsObject {
  hours: {
    date: string;
    start_time: string;
    end_time: string;
  }[];
}

interface Props {
  data: PropsObject;
}

const getNumericHour = (hour: string) =>
  parseInt(hour.split(":").slice(0, 1)[0]);

const NotFreeHoursModal = ({ data }: Props) => {
  const { closeModal } = useModal();

  const handleGoToBookings = () => {
    window.location.href = "/";
  };

  return (
    <div>
      <InfoText>Some of the hours from the basket are unavailable</InfoText>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Time range</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.hours.map((hour) => (
              <TableRow>
                <TableCell>
                  <LineThroughtItem>{hour.date}</LineThroughtItem>
                </TableCell>
                <TableCell>
                  <LineThroughtItem>
                    {getNumericHour(hour.start_time) > 12
                      ? getNumericHour(hour.start_time) - 12
                      : getNumericHour(hour.start_time)}
                    :00 {getNumericHour(hour.start_time) < 12 ? "am" : "pm"}
                    {" - "}
                    {getNumericHour(hour.end_time) > 12
                      ? getNumericHour(hour.end_time) - 12
                      : getNumericHour(hour.end_time)}
                    :00 {getNumericHour(hour.end_time) < 12 ? "am" : "pm"}
                  </LineThroughtItem>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ButtonsWrapper>
        <Button onClick={handleGoToBookings}>Go to bookings</Button>
        <Button bgColor="#e0e8f6" color="#000" onClick={() => closeModal()}>
          Back to basket
        </Button>
      </ButtonsWrapper>
    </div>
  );
};

export default NotFreeHoursModal;
