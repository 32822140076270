import React from "react";
import footerData from "./footerData";

import { StyledFooter, FooterLink, UlStyled, LiStyled } from "./StyledFooter";

const Footer = () => {
  return (
    <StyledFooter>
      <div>
        <UlStyled>
          {footerData.map(({ routeName, path }) => (
            <LiStyled key={routeName}>
              <FooterLink to={path}>{routeName}</FooterLink>
            </LiStyled>
          ))}
        </UlStyled>
      </div>
    </StyledFooter>
  );
};

export default Footer;
