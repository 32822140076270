import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const BookingDetailsModal = (data: any) => {
  console.log(data);

  const {
    data: { type, user },
  } = data;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row">
              Booked by
            </TableCell>
            <TableCell align="right">
              {type === "other" ? "User" : "Admin"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Name
            </TableCell>
            <TableCell align="right">
              {user.firstName} {user.surname}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Company Name
            </TableCell>
            <TableCell align="right">
              {user.companyName ? user.companyName : "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Email
            </TableCell>
            <TableCell align="right">{user.email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Contact number
            </TableCell>
            <TableCell align="right">{user.contactNumber}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Street
            </TableCell>
            <TableCell align="right">{user.street}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Town
            </TableCell>
            <TableCell align="right">{user.town}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Postcode
            </TableCell>
            <TableCell align="right">{user.postcode}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BookingDetailsModal;
