import styled from "styled-components";

export const TermsAndConditionsWrapper = styled.div`
  padding: 20px 100px;

  @media (max-width: 600px) {
    padding: 20px;
  }
`;

export const TermsPageTitle = styled.h1`
  font-size: 30px;
  font-weight: bold;
  margin: 20px 0;
  margin-bottom: 50px;
  text-align: center;
`;

export const TermsHeader = styled.p`
  margin: 20px 0;
  font-size: 24px;
  font-weight: bold;
`;

export const TermsLine = styled.p`
  margin-bottom: 10px;
  line-height: 26px;
`;
