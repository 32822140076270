import styled from 'styled-components';

export const ProfileWrapper = styled.div`
  padding: 20px 100px;
`;

export const ProfileHeader = styled.p`
  margin: 20px 0;
  font-size: 20px;
  font-weight: bold;
  color: #3c3744;
`;