import styled from "styled-components";

export const HeaderWrapper = styled.header`
  padding: 25px 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #d5c7bc; */
  background-color: #f8f6f6;

  @media (max-width: 1150px) {
    display: block;
    text-align: center;
    padding: 25px 50px;
  }
`;

export const Logo = styled.img`
  max-width: 150px;
`;

export const HeaderImgWrapper = styled.img`
  max-width: 200px;
  border-radius: 70%;
`;

export const LinksWrapper = styled.div`
  display: grid;
  gap: 20px;
  @media (max-width: 1150px) {
    justify-content: center;
    margin-top: 20px;
  }
`;

export const HeaderLink = styled.a`
  display: flex;
  gap: 15px;
  align-items: center;
  text-decoration: none;
  font-size: 20px;
  color: #3c3744;
`;

export const IconWrapper = styled.span`
  color: #4f9b5a;
`;

export const PageTitle = styled.div`
  font-size: 32px;
  font-family: "Workbench", sans-serif;
  line-height: 24px;

  @media (max-width: 1150px) {
    margin: 25px 0;
  }

  @media (max-width: 550px) {
    font-size: 24px;
  }

  @media (max-width: 450px) {
    font-size: 18px;
  }

  @media (max-width: 360px) {
    font-size: 15px;
  }

  & span {
    font-family: "Workbench", sans-serif;
  }

  & b {
    font-weight: normal;
    background-color: #4f9b5a;
    color: #fff;
    font-family: "Workbench", sans-serif;
    padding: 2px 4px;
  }
`;
