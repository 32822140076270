import ReCAPTCHA from "react-google-recaptcha";
import { Input, Label } from "../../../views/Basket/StyledBasket";
import { useState } from "react";
import Button from "../../atoms/Button";
import { auth } from "../../../firebase";
import { sendPasswordResetEmail } from "firebase/auth";

const ResetPasswordModal = () => {
  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const [reCaptchaToken, setReCaptchaToken] = useState<any>("");
  const [email, setEmail] = useState<string>("");

  const handleSubmitForm = async () => {
    if (!reCaptchaToken) {
      return;
    }

    if (!validateEmail(email)) {
      return alert("Bad email");
    }

    await sendPasswordResetEmail(auth, email);
    setEmail("");
    alert("Reset link sended");
  };

  return (
    <div>
      <div style={{ marginBottom: "10px" }}>
        <Label>Email</Label>
        <Input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div style={{ marginBottom: "10px" }}>
        <ReCAPTCHA
          size="normal"
          sitekey={process.env.REACT_APP_CAPTCHA_TOKEN || ""}
          onChange={(value: any) => setReCaptchaToken(value)}
        />
      </div>
      <Button onClick={() => handleSubmitForm()}>Send reset link</Button>
    </div>
  );
};

export default ResetPasswordModal;
