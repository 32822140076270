import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const StyledFooter = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 30px 0;
  font-weight: 600;
  font-size: 20px;
  align-items: center;
  text-decoration: none;
  gap: 10px;
`;

export const FooterLink = styled(NavLink)`
  color: #4f9b5a;
  text-decoration: none;
`;

export const UlStyled = styled.ul`
  list-style-type: none;

  justify-content: space-between;
  width: 100%;

  display: flex;
  gap: 10px;
`;

export const LiStyled = styled.li`
  display: flex;
  gap: 10px;

  /* Dodawanie pseudoelementu ::after */
  &::after {
    content: "";
    display: block;
    width: 2px;
    height: 22.5px;
    background-color: #4f9b5a;
  }

  &:last-of-type::after {
    display: none;
  }
`;
