import { Formik } from "formik";
import { loginFormElements } from "../../atoms/formElements/formElementsData";
import FormElement from "../../atoms/FormElement";
import Button from "../../atoms/Button";
import { loginFormValidationSchema } from "../../../validation/validationSchema";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../firebase";
import { useModal } from "../../../contexts/Modal";
import React, { LegacyRef, useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { env } from "process";
import { ReCAPTCHAProps } from "react-google-recaptcha";
import { ErrorText } from "../Register/StyledRegister";
import { BsFillExclamationTriangleFill } from "react-icons/bs";

interface FormFieldsInterface {
  email: string;
  password: string;
}

const Login = () => {
  const { closeModal, openModal } = useModal();

  const [reCaptchaToken, setReCaptchaToken] = useState<any>("");
  const [error, setError] = useState(false);

  const handleLogin = async (values: FormFieldsInterface) => {
    if (!reCaptchaToken) {
      return;
    }

    try {
      await signInWithEmailAndPassword(
        auth,
        values.email,
        values.password as string
      );
      closeModal();
    } catch (e) {
      setError(true);

      setTimeout(() => {
        setError(false);
      }, 5000);
    }
  };

  const resetPassword = () => {
    closeModal();
    openModal("reset_password");
  };

  return (
    <Formik
      validationSchema={loginFormValidationSchema}
      onSubmit={handleLogin}
      initialValues={{ email: "", password: "" }}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          {error && (
            <ErrorText>
              <BsFillExclamationTriangleFill />
              Incorrect password or email
            </ErrorText>
          )}
          {loginFormElements.map((el, index) => (
            <>
              <FormElement
                key={index}
                inputName={el.inputName}
                inputType={el.inputType}
                labelContent={el.labelContent}
              />
            </>
          ))}
          <div style={{ marginBottom: "10px" }}>
            <ReCAPTCHA
              size="normal"
              sitekey={process.env.REACT_APP_CAPTCHA_TOKEN || ""}
              onChange={(value: any) => setReCaptchaToken(value)}
            />
          </div>

          <Button type="submit">Submit</Button>
          <p
            style={{ marginTop: "10px", cursor: "pointer", fontSize: "13px" }}
            onClick={() => resetPassword()}
          >
            Forget password?
          </p>
        </form>
      )}
    </Formik>
  );
};

export default Login;
