import * as React from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import { useModal } from "../../contexts/Modal";
import Login from "../forms/Login";
import Register from "../forms/Register";
import BookingModal from "../atoms/BookingModal";
import RemoveHoursModal from "../atoms/RemoveHoursModal";
import NotFreeHoursModal from "../atoms/NotFreeHoursModal";
import BookingDetailsModal from "../atoms/BookingDetailsModal";
import ResetPasswordModal from "../atoms/ResetPasswordModal";

const style = {
  maxWidth: 450,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ModalWrapper = () => {
  const { isModalOpen, closeModal, modalComponent, data } = useModal();

  const renderModalContent = () => {
    switch (modalComponent) {
      case "login":
        return <Login />;
      case "register":
        return <Register />;
      case "reset_password":
        return <ResetPasswordModal />;
      case "booking":
        return <BookingModal data={data} />;
      case "remove_hours":
        return <RemoveHoursModal data={data} />;
      case "not_free_hours":
        return <NotFreeHoursModal data={data} />;
      case "booking_details":
        return <BookingDetailsModal data={data} />;
      default:
        return <></>;
    }
  };

  return (
    <Dialog open={isModalOpen} onClose={closeModal} scroll="paper">
      <Box sx={style}>{renderModalContent()}</Box>
    </Dialog>
  );
};

export default ModalWrapper;
