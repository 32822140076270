import {
  WeekDaysWrapper,
  CalendarDaysWrapper,
  DayWrapper,
  UnavailableDayWrapper,
  MonthsButtonsWrapper,
  MonthName,
  AvailableHoursWrapper,
  AvailableHoursData,
  AvailableHoursIcon,
  DayOfMonth,
  CalendarWrapper,
} from "./StyledCalendar";
import { useState, useEffect } from "react";

import { useModal } from "../../../contexts/Modal";
import Button from "../Button";
import { query, where, getDocs } from "firebase/firestore";
import { bookingCollection } from "../../../firebase";

export interface Booking {
  date: string;
  start_time: string;
  end_time: string;
  user_id: string;
  type: string;
  price: number;
  id: string;
  firstName: string;
  surname: string;
  companyName: string;
  flexible_hours?: number;
}

const getMonthName = (month: number) =>
  [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][month];

interface Props {
  logged: boolean;
}

const BookingCalendar = ({ logged }: Props) => {
  const { openModal } = useModal();

  const [currentMonthBookings, setCurrentMonthBookings] = useState<Booking[]>(
    []
  );

  const [monthRange, setMonthRange] = useState({
    start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    end: new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0,
      23,
      59
    ),
  });

  const isDayAvailable = (day: Date) => {
    if (
      (day.getMonth() < new Date().getMonth() &&
        day.getFullYear() === new Date().getFullYear()) ||
      day.getFullYear() < new Date().getFullYear() ||
      (day.getFullYear() === new Date().getFullYear() &&
        day.getMonth() === new Date().getMonth() &&
        day.getDate() < new Date().getDate())
    ) {
      return false;
    }

    if (
      day.getFullYear() === new Date().getFullYear() &&
      day.getMonth() === new Date().getMonth() &&
      day.getDate() === new Date().getDate()
    ) {
      return false;
    }

    const today = new Date();
    if (
      (today.getDate() === day.getDate() - 1 || day.getDate() === 1) &&
      today.getHours() >= 16
    ) {
      return false;
    }

    return true;
  };

  const [days, setDays] = useState<Date[]>([]);

  const getBookingsForMonth = async () => {
    const querySnapshot = await getDocs(
      query(
        bookingCollection,
        where("datetype", ">", monthRange.start),
        where("datetype", "<", monthRange.end)
      )
    );

    setCurrentMonthBookings(
      querySnapshot.docs.map(
        (booking) =>
          ({
            ...booking.data(),
            id: booking.id,
          } as Booking)
      )
    );
  };

  useEffect(() => {
    const tmpDays = [];

    if (monthRange.start.getDay() !== 1) {
      let dayOfFirstMonth = monthRange.start.getDay();
      if (dayOfFirstMonth === 0) {
        dayOfFirstMonth = 7;
      }

      const lastDayOfPrevMonth = new Date(
        monthRange.start.getFullYear(),
        monthRange.start.getMonth(),
        0
      ).getDate();

      for (
        let i = lastDayOfPrevMonth - dayOfFirstMonth + 2;
        i <= lastDayOfPrevMonth;
        i++
      ) {
        tmpDays.push(
          new Date(
            monthRange.start.getFullYear(),
            monthRange.start.getMonth() - 1,
            i
          )
        );
      }
    }

    for (let i = 1; i <= monthRange.end.getDate(); i++) {
      tmpDays.push(
        new Date(monthRange.start.getFullYear(), monthRange.start.getMonth(), i)
      );
    }

    setDays(tmpDays);

    getBookingsForMonth();
  }, [monthRange]);

  const getEventsForDate = (date: Date) => {
    const month =
      date.getMonth() < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;

    const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

    return currentMonthBookings.filter(
      (el) => el.date === `${monthRange.start.getFullYear()}-${month}-${day}`
    );
  };

  const setPrevMonth = () => {
    setMonthRange((prev) => ({
      start: new Date(prev.start.getFullYear(), prev.start.getMonth() - 1, 1),
      end: new Date(
        monthRange.end.getFullYear(),
        monthRange.end.getMonth(),
        0,
        23,
        59
      ),
    }));
  };

  const setNextMonth = () => {
    setMonthRange((prev) => ({
      start: new Date(prev.start.getFullYear(), prev.start.getMonth() + 1, 1),
      end: new Date(prev.end.getFullYear(), prev.end.getMonth() + 2, 0, 23, 59),
    }));
  };

  return (
    <div>
      <MonthName>
        {getMonthName(monthRange.start.getMonth())}{" "}
        {monthRange.start.getFullYear()}
      </MonthName>
      <MonthsButtonsWrapper>
        <Button bgColor="#e0e8f6" color="#000" onClick={() => setPrevMonth()}>
          Prev month
        </Button>
        <Button bgColor="#e0e8f6" color="#000" onClick={() => setNextMonth()}>
          Next month
        </Button>
      </MonthsButtonsWrapper>
      <CalendarWrapper>
        <WeekDaysWrapper>
          <li>Mon</li>
          <li>Tue</li>
          <li>Wed</li>
          <li>Thu</li>
          <li>Fri</li>
          <li>Sat</li>
          <li>Sun</li>
        </WeekDaysWrapper>
        <CalendarDaysWrapper>
          {days.map((day) =>
            isDayAvailable(day) ? (
              <DayWrapper
                onClick={(e) =>
                  openModal("booking", {
                    date: day,
                    bookings: getEventsForDate(day),
                    logged,
                  })
                }
              >
                <DayOfMonth>{day.getDate()}</DayOfMonth>
                <AvailableHoursWrapper>
                  <AvailableHoursData>
                    <AvailableHoursIcon /> {14 - getEventsForDate(day).length}{" "}
                    hrs
                  </AvailableHoursData>{" "}
                </AvailableHoursWrapper>
              </DayWrapper>
            ) : (
              <UnavailableDayWrapper>{day.getDate()}</UnavailableDayWrapper>
            )
          )}
        </CalendarDaysWrapper>
      </CalendarWrapper>
    </div>
  );
};

export default BookingCalendar;
