import {
  TermsAndConditionsWrapper,
  TermsHeader,
  TermsLine,
  TermsPageTitle,
} from "./StyledTermsAndConditions";

const TermsAndConditions = () => {
  return (
    <TermsAndConditionsWrapper>
      <TermsPageTitle>Terms & Conditions</TermsPageTitle>
      <TermsLine>
        <TermsHeader>1. OUR AGREEMENT</TermsHeader>
      </TermsLine>
      <TermsLine>
        a. These Terms of Business supplement the agreement set out in a
        Booking. They do not give rise to any right for either of us to require
        a further Booking.
      </TermsLine>
      <TermsLine>
        b. We will start work when you accept our Booking. You can accept by
        letting us know that you accept, or by asking us to start work.{" "}
      </TermsLine>
      <TermsLine>
        c. The Services to be provided are set out in the Booking. They can be
        amended by exchange of emails or by issuing a revised Booking.
      </TermsLine>
      <TermsLine>
        <TermsHeader>2. EXPECTATIONS</TermsHeader>
      </TermsLine>
      <TermsLine>
        a. Quality standards that are unique to the project are set out in the
        Booking.
      </TermsLine>
      <TermsLine>
        b. The work is normally undertaken at our premises. When asked to travel
        to other premises, travel time and travel expenses will be charged.
      </TermsLine>
      <TermsLine>
        c. Our objectives, expressed in general terms, will be
      </TermsLine>
      <ul>
        <li>To support your business;</li>
        <li>To understand your key timings.</li>
      </ul>
      <TermsLine>
        d. In order for us to achieve the best results for you, we need your
        active co-operation. Specifically, you should
      </TermsLine>
      <ul>
        <li>Promptly provide the resources you are responsible for;</li>
        <li>Provide clear guidelines for the tasks required.</li>
      </ul>
      <TermsLine>
        e. We will normally provide all equipment needed to perform the
        Services. We will set out in the Booking any items are chargeable to
        you.
      </TermsLine>
      <TermsLine>
        f. Insurance <br /> If we carry professional indemnity or IR35
        insurance, we set out the amount we are insured for in the Booking form.
        If that field is blank that means we are not currently insured. If you
        wish us to take out insurance or additional insurance, we are happy to
        do so if you agree to pay the additional cost. Normally this is an
        annual cost, and it may not be possible to refund the charge if you do
        not use us for the exact year that our insurance runs. You can ask us
        for a copy of our current certificates of cover and policy terms.
      </TermsLine>
      <TermsLine>
        <TermsHeader>3. BUSINESS ARRANGEMENTS</TermsHeader>
      </TermsLine>
      <TermsLine>
        a. Our Services are provided on a ‘business-to-business’ basis.
      </TermsLine>
      <ul>
        <li>
          The way we work is part of our trade know-how and shall not be under
          your direction or control.
        </li>
        <li>
          We shall continue to market our services and work for other clients
          and maintain an independent business of our own.
        </li>
      </ul>
      <TermsLine>
        b. Authority <br /> The person named in the Booking will be our main
        contact and has the authority to agree payments and to agree changes to
        a Booking. We will not order any goods or services on your behalf unless
        it is authorised by that person.
      </TermsLine>
      <TermsLine>
        <TermsHeader>4. BUSINESS CONTINUITY AND RESILIENCE</TermsHeader>
      </TermsLine>
      <TermsLine>
        a. Holiday, sickness and emergency cover We may nominate one or more
        substitutes for holiday and sickness cover. These will be given the
        necessary access when they are about to be used.
      </TermsLine>
      <TermsLine>
        b. Expertise and focus <br /> From time to time we may use experts to
        supplement our own expertise where such expertise is needed and not
        provided via a 3rd party contracted directly with you.
      </TermsLine>
      <TermsLine>
        c. Availability and deadlines <br /> We may delegate elements of your
        work, or entire projects to suitably qualified members of our team to
        provide appropriate depth of resources to meet your business deadlines.
      </TermsLine>
      <TermsLine>
        d. All our team are contracted to confidentiality and appropriate GDPR
        standards where necessary.
      </TermsLine>
      <TermsLine>
        <TermsHeader>
          5. TIMING AND STANDARD OF PROVISION OF SERVICES
        </TermsHeader>
      </TermsLine>
      <TermsLine>
        a. We will use our reasonable endeavours to deliver Services according
        to the timetable described in the Booking. Where work is undertaken by
        retainer you must specify your priorities and deadlines in line with the
        number of hours/posts that you have purchased. We will let you know if
        we expect that campaign or other deadlines may not be met.
      </TermsLine>
      <TermsLine>
        b. If you want more work than the time/posts you have purchased, you
        need either to scale back your requirements or purchase more hours/posts
        subject to availability.
      </TermsLine>
      <TermsLine>
        c. Timetables <br /> Our ability to meet timetables depends on your
        giving us access in good time to all the information or resources we
        need from you. If you are late with information or resources, we reserve
        the right to reschedule Services or you may simply have lost that period
        of posting. There is no automatic right to roll over posts from an
        earlier period.
      </TermsLine>
      <TermsLine>
        d. Availability <br /> Our normal working hours are displayed on our web
        site, and/or set out in the Booking. Availability outside these hours
        cannot be guaranteed without agreement in advance, and work outside
        those availability hours will be subject to additional work surcharges.
      </TermsLine>
      <TermsLine>
        e. Proof reading and sign off <br /> While we do everything we can to
        ensure the accuracy of the work we do for you, the final sign off rests
        with you and it is your responsibility to check the work before it goes
        out.
      </TermsLine>
      <TermsLine>
        <TermsHeader>6. FEES, PAYMENTS AND EXPENSES</TermsHeader>
      </TermsLine>
      <TermsLine>
        a. Fees and expenses are charged in accordance with the Booking.
        Out-of-pocket expenses (including travel, stock pictures, videos and
        music, USBs, DVDs, CDs) will be charged as an additional charge. Where
        applicable VAT will be charged at the appropriate rate.
      </TermsLine>
      <TermsLine>
        b. Normal hours of work and availability are set out in the Booking
        form. For work outside these hours, an additional rate may be applied as
        set out in the Booking form or 150% of the hourly rate fee for
        time-based bookings (or the equivalent).
      </TermsLine>
      <TermsLine>
        c. For urgent work given at less than 24 hours’ notice, an urgent work
        rate may be charged at the rate set out in the Booking, or, if none, at
        150% of the hourly rate fee for time-based bookings (or the equivalent).
      </TermsLine>
      <TermsLine>
        d. Out of hours and urgent work rates may both be charged for the same
        work if it is both urgent and out of normal hours. We will apply the
        out-of-hours work surcharge to the normal hourly rate and then the
        urgent work surcharge to the resulting out-of-hours rate.
      </TermsLine>
      <TermsLine>
        e. Where the Booking is for a fixed fee retainer or project, additional
        work outside the scope of the original Booking will be charged at our
        normal hourly rate (subject to Clauses b to 6.d above) unless stated
        otherwise in the Booking.
      </TermsLine>
      <TermsLine>
        f. We may agree to a maximum of 10% of unused hours or posts being
        carried forward provided our invoices are fully and promptly paid in
        accordance with our terms. Hours or posts carried forward must be used
        in the following month or they will be lost. Hours/posts carried forward
        from one month to the next will be used first before the month’s
        retainer hours/posts.
      </TermsLine>
      <TermsLine>
        g. Deposits are due for payment before work commences. The non-payment
        of a deposit may delay starting the work even if you have accepted the
        terms and asked us to start. Payment means when cleared funds appear in
        our bank account.
      </TermsLine>
      <TermsLine>
        h. Payment is due as set out on the Booking or if not specified there
        within seven calendar days from the date on the invoice. If you do not
        pay by the due date, we may reschedule further work until payment is
        made. Additional charges may be levied for PayPal, credit card payments,
        specific payment methods – see Booking.
      </TermsLine>
      <TermsLine>
        i. We reserve the right to charge interest on overdue amounts at the
        rate set out in the Booking, or where the Booking does not specify at
        the rate of 2.22% per month (equivalent to unauthorised overdraft rate
        from the bank). Subsequent payments will be applied to interest and
        finance charges first, and then applied to fees/costs outstanding.
      </TermsLine>
      <TermsLine>
        j. Any time and expenses incurred in responding to your requests to
        audit data for GDPR compliance or complying with an external legal
        body’s legal requirements to disclose information or submit to audit may
        result in charges at our normal hourly rate (or the equivalent) for the
        work incurred.
      </TermsLine>
      <TermsLine>
        k. Upon termination of a Booking further time-based charges may be
        incurred in handing over, returning, data, or responding to enquiries.
        This would be charged at our normal hourly rate or the equivalent.
      </TermsLine>
      <TermsLine>
        l. If you specify that we should securely store data for you in our
        software or systems for longer than six weeks after the termination of a
        Booking, we reserve the right to charge for this storage and securing at
        the rate of £500 a year or such other amount as specified in the
        Booking.
      </TermsLine>
      <TermsLine>
        m. We have quoted our fees on the basis of a business to business
        arrangement where we are entitled to be paid our fees without
        deductions.
      </TermsLine>
      <ul>
        <li>
          <ul>
            <li>
              If you make any tax withholding or deduction of PAYE and/or NI
              from the face value of our invoices; and/or
            </li>
            <li>
              If by your action or inaction you bring our Booking within scope
              of IR35 or any other tax obligation that results in the need for
              you to make deductions at source; and/or
            </li>
            <li>
              If you insist that we work under an umbrella company (so requiring
              us to bear the burden of charges and fees),
            </li>
          </ul>
        </li>
      </ul>
      <TermsLine>
        then the invoice amount(s) will automatically be adjusted upwards to
        ensure we receive payment of the face value of the original invoice(s)
        and/or fees as quoted on the Booking. We may also charge you for
        umbrella company and other associated costs, including and not
        restricted to National Insurance.
      </TermsLine>
      <TermsLine>
        Bookings will be upwardly adjusted from that point onwards so that the
        nett receipts against the invoice match the value in the original
        Booking and/or quotation for the work.
      </TermsLine>
      <TermsLine>
        n. We shall deduct and pay over to HMRC any tax and national insurance
        that may be required under any tax obligation imposed on us. If you are
        involved in a dispute with HMRC over who should be paying such tax, we
        will produce the relevant receipts and other existing paperwork to help
        you reduce or resist the demand.
      </TermsLine>
      <TermsLine>
        o. Time-based Bookings only <br /> Time-based bookings are charged in
        15-minute slots, so a five-minute call may incur a 15-minute charge if
        this is a unique call during the day. Fees will be subject to a minimum
        one-hour charge on days when work is required.
      </TermsLine>
      <TermsLine>
        p. We have some software and equipment we use at no additional charge to
        you. But where we need license fees or usage fees to provide support for
        you, we will charge you the cost of any licenses you have authorised us
        to purchase. We will normally provide all equipment needed to perform
        the Services. We will set out in the Booking (or Booking amendments)
        what they are and whether they are chargeable to you.
      </TermsLine>
      <TermsLine>
        <TermsHeader>7. RIGHTS TO USE / COPYRIGHT</TermsHeader>
      </TermsLine>
      <TermsLine>
        a. The Rights in work done under any Booking shall be ours. Upon payment
        of our fees and charges we will assign to you the Rights in Materials
        uniquely created under the Booking. We agree to sign any further
        documents needed to complete the transfer of those Rights to you. This
        will not include the Rights to any templates or structures or
        methodologies that we used to create Materials.
      </TermsLine>
      <TermsLine>
        b. Information and documents which we provide to you remain our absolute
        property unless and until assigned to you.
      </TermsLine>
      <TermsLine>
        c. You promise not to breach any third-party copyright, trade/service
        mark or privacy rights in sending us material to work on. You promise
        not to use any confidential or restricted information that belongs to
        someone else in sending us work.
      </TermsLine>
      <TermsLine>
        d. We will keep full records of the work that we have done for you and
        the contacts we have made with people on your behalf.
      </TermsLine>
      <TermsLine>
        e. We will not access, use, copy, distribute or publish any part of any
        information, data or documents created uniquely for you (once paid for),
        for our own or any other person’s benefit or purposes.
      </TermsLine>
      <TermsLine>
        <TermsHeader>8. POLICIES AND PROCEDURES</TermsHeader>
      </TermsLine>
      <TermsLine>
        a. Resolving problems <br /> If there is anything about your project
        that is not going as you want, or if you have any query or complaint,
        speak to us straight away.
      </TermsLine>
      <TermsLine>
        b. Health and Safety <br /> When working at our own premises, we are
        responsible for our own health and safety.
      </TermsLine>
      <TermsLine>
        c. Working at your premises <br /> We may from time to time work at your
        premises and be covered by your health and safety policy.
      </TermsLine>
      <TermsLine>
        d. We handle Personal Data in accordance with our data privacy policy.
      </TermsLine>
      <TermsLine>
        e. Where a Booking involves the transfer of Personal Data outside the
        EU/EEA, you and we will enter into and be bound by a Non-EU/EEA Model
        Clauses agreement.
      </TermsLine>
      <TermsLine>
        <TermsHeader>9. CONFIDENTIAL INFORMATION</TermsHeader>
      </TermsLine>
      <TermsLine>
        a. Your information and our confidentiality <br />
        You may need to share Confidential Information with us. It may be
        business information or information about individuals (which is also
        covered in Clause 10 below). We will only use Confidential Information
        that you send us to perform the Services set out in the Booking or if we
        are required to disclose it by law. We may keep some Confidential
        Information to keep a record of what we did for you. We will keep
        personal data in line with our data retention policy (a copy of which
        can be found with our data privacy policy).
      </TermsLine>
      <TermsLine>
        b. Documents and information <br /> We will need to agree with you a
        safe and secure system of you sending us your confidential documents and
        information (and us returning them to you). We are not liable for data
        that is not securely transmitted to us.
      </TermsLine>
      <TermsLine>
        c. Log-ins <br /> Where you wish us to access systems that contain
        information that identifies living individuals, you should provide us
        with a unique log in to your existing software platforms and systems.
      </TermsLine>
      <TermsLine>
        d. Where multi-user log ins are not available you will securely share
        passwords through password lockers and change those passwords at
        appropriate intervals.
      </TermsLine>
      <TermsLine>
        e. We will not set up any social media or email account or
        fan/group/web/ forum pages using our name or our brand name(s), or for
        people to respond to unless your Booking specifically says so. The
        default user will be set up using your information and contact details
        unless you specify otherwise.
      </TermsLine>
      <TermsLine>
        f. Note that we may make and keep temporary backups to ensure continuity
        of service.
      </TermsLine>
      <TermsLine>
        <TermsHeader>10. DATA PRIVACY</TermsHeader>
      </TermsLine>
      <TermsLine>
        a. We will process your own Personal Data in line with our data privacy
        policy.
      </TermsLine>
      <TermsLine>
        b. When you want us to access or use Personal Data about someone other
        than you (3rd party data), you must have completed the appropriate Data
        Processing Form and send a copy by email from your usual business
        address. While processing personal data in the provision of Services, we
        will be acting as ‘data processor’ for you, and you are the ‘data
        controller’.
      </TermsLine>
      <TermsLine>
        c. We will process 3rd Party Data on your behalf only in response to
        your written instructions (which may be in the Booking, Data Processing
        Form, or in separate email or document) except where we are required by
        law to do so.
      </TermsLine>
      <TermsLine>
        d. We are subject to a duty of confidence (see Clause a and Clause 4.d
        (in relation to our team)).
      </TermsLine>
      <TermsLine>
        e. We will take appropriate measures to ensure the security of our
        Processing of your 3rd Party Data.
      </TermsLine>
      <TermsLine>
        f. We will assist you in allowing 3rd parties to exercise any of their
        Data Privacy rights (including subject access). This will result in
        additional time-related charges (see Clause 6 above).
      </TermsLine>
      <TermsLine>
        g. We will apply the security measures you set out in the Data
        Processing Form for protecting and securing your data.
      </TermsLine>
      <TermsLine>
        h. We will email you if we become aware of a Personal Data Breach. We
        will assist you in your investigations in establishing how this
        occurred. We will assist you, as you may reasonably require, in meeting
        your Data Privacy obligations in relation to the security of processing,
        the notification of personal data breaches and data protection impact
        assessments. This will result in additional time-related charges (see
        Clause 6 above)
      </TermsLine>
      <TermsLine>
        i. On written request by you, we will delete or return all 3rd Party
        Data supplied by you or assembled by us in the course of delivering
        Services to you. This will result in additional time-related charges
        (see Clause 6 above).
      </TermsLine>
      <TermsLine>
        j. Unless the Data Processing Form specifies otherwise, we will delete
        all 3rd party data supplied by you from systems within our control
        within six weeks of the end of the Booking without returning copies to
        you; we will not remove data from systems you have given us access or
        log-ins to, since those systems will remain under your control and we
        would expect you to be removing our access to them after the end of a
        Booking.
      </TermsLine>
      <TermsLine>
        k. If you wish us to store 3rd Party Data beyond the termination of the
        Booking and the six-week run on, you must specify this in the Data
        Processing Form. This will result in additional time-related charges
        (see Clause 6 above).
      </TermsLine>
      <TermsLine>
        l. You may audit and inspect how we handle your 3rd party data. We will
        provide you with whatever information you need to ensure that the
        relevant Data Privacy obligations are being met; this will result in
        additional time-related charges (see Clause 6 above).
      </TermsLine>
      <TermsLine>
        m. We will tell you immediately if we believe we are asked to do
        something infringing Data Privacy law. We are not legal experts and it
        is up to you to take appropriate legal advice on how to comply with Data
        Privacy rules and regulations.
      </TermsLine>
      <TermsLine>
        n. If you have instructed us to do something we believe is not within
        the law, we reserve the right to take advice from the Information
        Commissioner’s help line and to act in accordance with their indications
        or advice.
      </TermsLine>
      <TermsLine>
        o. If you direct us to perform work that is counter to ICO indications
        or advice, we may seek a deposit from you against the likelihood of
        fines or other action being taken against us. We may specify such sum as
        we think is appropriate.
      </TermsLine>
      <TermsLine>
        p. It is always your responsibility to specify suitably secure platforms
        and processes and to share data with us in a secure way.
      </TermsLine>
      <TermsLine>
        <TermsHeader>11. RESTRICTION AND LIMITATION</TermsHeader>
      </TermsLine>
      <TermsLine>
        a. Whilst working with us, you may be working with our associates and
        employees who support us. They are all under contractual terms that
        prohibit them from working directly for our clients other than through
        us, and you must not participate in them breaching their obligations to
        us. If you want one of our team to work directly for you, we would
        consider releasing them from their contractual obligations for a
        suitable fee representing the all-in cost of locating, recruiting and
        training a substitute and our loss of profit during this period.
      </TermsLine>
      <TermsLine>
        b. We will not be liable for failure to provide services where it is not
        reasonably practicable to do so due to circumstances beyond our control.
      </TermsLine>
      <TermsLine>
        c. Indemnity <br /> You will indemnify us against any fines, costs,
        expenses, losses or other harm that comes to us from following any
        unlawful instruction or instruction to act in an unlawful way that was
        given by you.
      </TermsLine>
      <TermsLine>
        d. Limitation of liability <br /> Our fee rates are determined on the
        basis of the limits of liability set out in the next four paragraphs.
        You may request that we agree to a higher limit of liability (provided
        insurance cover can be obtained therefor) in which case our fee rates
        may be adjusted, or an additional charge may be made.
      </TermsLine>
      <TermsLine>
        e. There shall be no personal liability of any of our principals,
        directors, partners, employees, agents or sub-contractors arising in any
        way out of the performance or non-performance of services or relating to
        the supply of products.
      </TermsLine>
      <TermsLine>
        f. We shall have no liability for any indirect or consequential losses
        or expenses suffered by you, however caused, including but not limited
        to loss of anticipated profits, goodwill, reputation, business receipts
        or contracts, or losses or expenses resulting from third party claims.
      </TermsLine>
      <TermsLine>
        g. Our aggregate financial liability to you shall not exceed the pro
        rata fees paid for the action(s) which gave rise to such liability.
      </TermsLine>
      <TermsLine>
        h. Nothing in these Terms shall be interpreted as excluding or
        restricting any legal liability on us or others where liability cannot
        legally be excluded or restricted.
      </TermsLine>
      <TermsLine>
        <TermsHeader>TERMINATION</TermsHeader>
      </TermsLine>
      <TermsLine>
        a. Bookings end on the completion date specified in the Booking or on
        completion of the work whichever is earlier.
      </TermsLine>
      <TermsLine>
        b. Either party may terminate a Booking summarily at any time by giving
        notice in writing to the other party if
      </TermsLine>
      <ul>
        <li>
          the other party commits a material breach of agreement and such breach
          is not remediable;
        </li>
        <li>
          the other party commits a material breach of agreement which is not
          remedied within 14 days of receiving written notice of such breach and
          requiring its remedy from the injured party;
        </li>
        <li>
          any consent, licence or authorisation held by the other party is
          revoked or modified such that the other party is no longer able to
          comply with its obligations under the Booking or receive any benefit
          to which it is entitled; or
        </li>
        <li>
          the other party is subject to any procedure or takes any action
          relating to their potential insolvency or winding up or being placed
          into administration or entering into a creditors’ voluntary
          arrangement or any other process reasonably indicating to the first
          party that the other party will not be able to meet its obligations
          under the Booking as they fall due.
        </li>
        <li>
          they choose to terminate without reason, by giving one month’s notice
          in writing.
        </li>
      </ul>
    </TermsAndConditionsWrapper>
  );
};

export default TermsAndConditions;
