import {
  Dispatch,
  SetStateAction,
  createContext,
  useState,
  useContext,
  useEffect,
} from "react";

interface Hour {
  date: string;
  start_time: string;
  end_time: string;
  hours?: number;
}

interface BasketInterface {
  hours: Hour[];
  setHours: Dispatch<SetStateAction<Hour[]>>;
}

const BasketContext = createContext<BasketInterface>({
  hours: [],
  setHours: () => {},
});

interface ModalProviderProps {
  children: JSX.Element;
}

const BasketContextProvider = ({ children }: ModalProviderProps) => {
  const [hours, setHours] = useState<Hour[]>([]);

  return (
    <BasketContext.Provider value={{ hours, setHours }}>
      {children}
    </BasketContext.Provider>
  );
};

export default BasketContextProvider;

export const useBasket = () => {
  const { hours, setHours } = useContext(BasketContext);

  useEffect(() => {
    if (localStorage.getItem("clear_basket")) {
      localStorage.removeItem("va_reservations");
      localStorage.removeItem("clear_basket");
    } else if (localStorage.getItem("va_reservations")) {
      const checkDate = new Date();
      checkDate.setDate(checkDate.getDate() + 1);
      checkDate.setHours(0);
      checkDate.setMinutes(0);

      const hours = JSON.parse(
        localStorage.getItem("va_reservations") as string
      ).filter((el: Hour) => {
        return (
          checkDate.getTime() <
            new Date(`${el.date} ${el.start_time}`).getTime() ||
          el.date === "flexible"
        );
      });

      localStorage.setItem("va_reservations", JSON.stringify(hours));
      setHours(hours);
    }
  }, []);

  const addToLocalStorage = (book: Hour) => {
    if (localStorage.getItem("va_reservations")) {
      localStorage.setItem(
        "va_reservations",
        JSON.stringify([
          ...JSON.parse(localStorage.getItem("va_reservations") as string),
          book,
        ])
      );
    } else {
      localStorage.setItem("va_reservations", JSON.stringify([book]));
    }

    setHours((prev) => [...prev, book]);
  };

  const addHourToBasket = (
    date: string,
    start_hour: string,
    hours: number = 0
  ) => {
    if (date === "flexible") {
      return addToLocalStorage({
        date: "flexible",
        start_time: "flexible",
        end_time: "flexible",
        hours: hours,
      });
    }

    const numericStartHour = parseInt(start_hour);
    const fullStartHour =
      (numericStartHour < 10 ? "0" + numericStartHour : numericStartHour) +
      ":00";

    const fullEndHour =
      (numericStartHour + 1 < 10
        ? "0" + (numericStartHour + 1)
        : numericStartHour + 1) + ":00";

    const bookDate = new Date(date);
    const bookYear = bookDate.getFullYear();
    const bookMonth = bookDate.getMonth() + 1;
    const bookDay = bookDate.getDate();

    const book: Hour = {
      date: `${bookYear}-${bookMonth < 10 ? "0" + bookMonth : bookMonth}-${
        bookDay < 10 ? "0" + bookDay : bookDay
      }`,
      start_time: fullStartHour,
      end_time: fullEndHour,
    };

    addToLocalStorage(book);
  };

  const isHourInBasket = (date: string, start_hour: string) => {
    if (!localStorage.getItem("va_reservations")) {
      return false;
    }

    const bookDate = new Date(date);
    const bookYear = bookDate.getFullYear();
    const bookMonth = bookDate.getMonth() + 1;
    const bookDay = bookDate.getDate();

    const fullStartHour =
      (parseInt(start_hour) < 10
        ? "0" + parseInt(start_hour)
        : parseInt(start_hour)) + ":00";

    return (
      hours.findIndex(
        (el) =>
          el.date ===
            `${bookYear}-${bookMonth < 10 ? "0" + bookMonth : bookMonth}-${
              bookDay < 10 ? "0" + bookDay : bookDay
            }` && el.start_time === fullStartHour
      ) !== -1
    );
  };

  const deleteHourFromBasket = (
    date: string,
    start_hour: string,
    hours: number = 0
  ) => {
    const bookDate = new Date(date);
    const bookYear = bookDate.getFullYear();
    const bookMonth = bookDate.getMonth() + 1;
    const bookDay = bookDate.getDate();

    if (hours) {
      return setHours((prev) => {
        const tmp = [...prev];
        const deleteIndex = tmp.findIndex(
          (el) => el.date === "flexible" && el.hours === hours
        );
        tmp.splice(deleteIndex, 1);

        localStorage.setItem("va_reservations", JSON.stringify(tmp));

        return [...tmp];
      });
    }

    const fullStartHour =
      (parseInt(start_hour) < 10
        ? "0" + parseInt(start_hour)
        : parseInt(start_hour)) + ":00";

    return setHours((prev) => {
      const tmp = [...prev];
      const deleteIndex = tmp.findIndex(
        (el) =>
          el.date ===
            `${bookYear}-${bookMonth < 10 ? "0" + bookMonth : bookMonth}-${
              bookDay < 10 ? "0" + bookDay : bookDay
            }` && el.start_time === fullStartHour
      );
      tmp.splice(deleteIndex, 1);

      localStorage.setItem("va_reservations", JSON.stringify(tmp));

      return [...tmp];
    });
  };

  const clearBasket = () => {
    localStorage.setItem("va_reservations", "[]");
    setHours([]);
  };

  return {
    hours,
    clearBasket,
    addHourToBasket,
    isHourInBasket,
    deleteHourFromBasket,
  };
};
