import React from "react";
import { mainTheme } from "../../../globalStyles/Themes/mainTheme";
import { StyledButton } from "./StyledButton";

interface Props {
  children: JSX.Element | string;
  bgColor?: string;
  onClick?(): any;
  type?: "button" | "submit";
  color?: string;
}

const Button = ({
  children,
  bgColor = "#4f9b5a",
  color = "#fff",
  onClick,
  type,
}: Props) => {
  return (
    <StyledButton type={type} onClick={onClick} bgColor={bgColor} color={color}>
      {children}
    </StyledButton>
  );
};

export default Button;
