import { SyntheticEvent, useState } from "react";
import Button from "../Button";
import { useBasket } from "../../../contexts/Basket";

interface IProps {
  logged: boolean;
}

const BookingHours = ({ logged }: IProps) => {
  const [hoursInput, setHoursInput] = useState(1);

  const { addHourToBasket } = useBasket();

  const handleInputChange = (e: SyntheticEvent) => {
    const value = (e.target as HTMLInputElement).value.replace(/[^\d]/, "");
    const parsedValue = parseInt(value);

    if (isNaN(parsedValue) || (!isNaN(parsedValue) && parsedValue < 1)) {
      setHoursInput(1);
    } else {
      if (parsedValue > 100) {
        setHoursInput(100);
      } else {
        setHoursInput(parsedValue);
      }
    }
  };

  const bookHours = () => {
    addHourToBasket("flexible", "flexible", hoursInput);
    setHoursInput(1);
  };

  return (
    <div>
      <p>How many hours do you require?</p>
      <div>
        <input type="number" value={hoursInput} onChange={handleInputChange} />
      </div>
      <Button onClick={() => bookHours()}>Book</Button>
    </div>
  );
};

export default BookingHours;
