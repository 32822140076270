import styled from "styled-components";

export const InfoText = styled.p`
  margin-bottom: 30px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
