const footerData = [
  {
    path: '/terms-and-conditions',
    routeName: "Terms & Conditions",
  },

  {
    path: '/contact-us',
    routeName: "Contact",
  },
];

export default footerData;