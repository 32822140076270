import {
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Paper,
} from "@mui/material";
import {
  BasketWrapper,
  PaymentDetailsWrapper,
  PaymentDetailsItem,
  PaymentDetailsItemTotal,
  EmptyBasketInfo,
  BasketSpinnerWrapper,
  Label,
  Input,
} from "./StyledBasket";
import { useBasket } from "../../contexts/Basket";
import { useModal } from "../../contexts/Modal";
import Button from "../../components/atoms/Button";
import {
  STANDARD_PRICE,
  EVENING_PRICE,
  WEEKEND_PRICE,
  FLEX_PRICE,
} from "../../data/prices";
import { FaSpinner } from "react-icons/fa";

import {
  bookingCollection,
  firestore,
  paymentsCollection,
  usersCollection,
} from "../../firebase";
import { addDoc, query, where, getDocs, setDoc, doc } from "firebase/firestore";
import { useUser } from "../../contexts/User";
import { useNavigate } from "react-router-dom";
import {
  ElementsConsumer,
  PaymentElement,
  Elements,
} from "@stripe/react-stripe-js";
import { Stripe, StripeElements, loadStripe } from "@stripe/stripe-js";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { adminBookingFormElement } from "../../components/atoms/formElements/formElementsData";
import FormElement from "../../components/atoms/FormElement";
import Select from "react-select";

const stripePromise = loadStripe(
  "pk_live_51N99oVIVuaCDo1Gn36cnkaBZmLhP2dKmUyOCabaI3ax6aQt3nOd2ndVCAkY3EJRJHngeFLkTdpctqPSBZ82pvhBd00n6DR0RLm"
);

interface CheckoutFormInterface {
  stripe: Stripe | null;
  elements: StripeElements | null;
  user: any;
  amount: number;
  checkNotFreeHours: () => Promise<boolean>;
  saveHoursToDB: (payment_id: string, bookingUser: any) => void;
  bookingUser: any;
  validatePaymentUserInfo: () => boolean;
  flexibleHoursAmount: number;
}

const CheckoutForm = ({
  stripe,
  elements,
  user,
  amount,
  checkNotFreeHours,
  saveHoursToDB,
  bookingUser,
  validatePaymentUserInfo,
  flexibleHoursAmount,
}: CheckoutFormInterface) => {
  const [paymentInProgress, setPaymentInProgress] = useState<boolean>(false);

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (await checkNotFreeHours()) {
      return;
    }

    if (!validatePaymentUserInfo()) {
      return;
    }

    const validation = await elements.submit();

    if (validation.error) {
      return;
    }

    setPaymentInProgress(true);

    const request = await fetch(
      `https://us-central1-zoomva-bc9fb.cloudfunctions.net/app/j3zipjbcmgrzc7wrfetwa0h1v7vxzc`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: amount * 100,
          description: `Payment ${user.firstName} ${user.surname}`,
          token: "en6SSiSRV7GgOl5bK4XI5D2vLGzwKAFn",
        }),
      }
    );

    const response = await request.json();

    const firebasePayment = await addDoc(paymentsCollection, {
      stripe_id: response.id,
      user_id: user.id,
      amount: amount,
      status: "in_progress",
      date: new Date(),
      flexible_hours: flexibleHoursAmount,
      ...bookingUser,
    });

    await saveHoursToDB(firebasePayment.id, bookingUser);

    if (!user.town) {
      await setDoc(doc(firestore, "users", user.id), bookingUser, {
        merge: true,
      });
    }

    localStorage.setItem("clear_basket", "true");

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "https://swiftbsltd.com",
      },
      clientSecret: response.token,
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <div style={{ marginTop: "40px" }}>
        <Button type="submit">Pay</Button>
      </div>

      {paymentInProgress && (
        <BasketSpinnerWrapper>
          <FaSpinner />
        </BasketSpinnerWrapper>
      )}
    </form>
  );
};

const InjectedCheckoutForm = ({
  user,
  amount,
  checkNotFreeHours,
  saveHoursToDB,
  bookingUser,
  validatePaymentUserInfo,
  flexibleHoursAmount,
}: {
  user: any;
  amount: number;
  checkNotFreeHours: () => Promise<boolean>;
  saveHoursToDB: (payment_id: string, bookingUser: any) => void;
  bookingUser: any;
  validatePaymentUserInfo: () => boolean;
  flexibleHoursAmount: number;
}) => {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm
          stripe={stripe}
          elements={elements}
          user={user}
          amount={amount}
          checkNotFreeHours={checkNotFreeHours}
          saveHoursToDB={saveHoursToDB}
          bookingUser={bookingUser}
          validatePaymentUserInfo={validatePaymentUserInfo}
          flexibleHoursAmount={flexibleHoursAmount}
        />
      )}
    </ElementsConsumer>
  );
};

interface ObjectKeys {
  [key: string]: string | undefined;
}

interface SearchableUser extends ObjectKeys {
  id?: string;
  firstName: string;
  surname: string;
  companyName: string;
  email: string;
  contactNumber: string;
  street?: string;
  postcode?: string;
  town?: string;
}

interface SelectOptions {
  value: SearchableUser;
  label: string;
}

const getNumericHour = (hour: string) =>
  parseInt(hour.split(":").slice(0, 1)[0]);

const getDayName = (date: string) =>
  ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][new Date(date).getDay()];

const Basket = () => {
  const { hours, deleteHourFromBasket, clearBasket } = useBasket();
  const { openModal, closeModal } = useModal();
  const { user } = useUser();

  const [searchbleUsers, setSearchableUsers] = useState<SelectOptions[]>([]);

  const [bookingUser, setBookingUser] = useState<SearchableUser>({
    firstName: user && user.id !== "DkBOnsN5oB80iFNczQTF" ? user.firstName : "",
    surname: user && user.id !== "DkBOnsN5oB80iFNczQTF" ? user.surname : "",
    email: user && user.id !== "DkBOnsN5oB80iFNczQTF" ? user.email : "",
    contactNumber:
      user && user.id !== "DkBOnsN5oB80iFNczQTF" ? user.contactNumber : "",
    companyName:
      user && user.id !== "DkBOnsN5oB80iFNczQTF" ? user.companyName : "",
    town: user && user.id !== "DkBOnsN5oB80iFNczQTF" ? user.town : "",
    postcode: user && user.id !== "DkBOnsN5oB80iFNczQTF" ? user.postcode : "",
    street: user && user.id !== "DkBOnsN5oB80iFNczQTF" ? user.street : "",
  });

  const [bookingUserErrors, setBookingUserErrors] = useState<any>({});

  const handleSelectUser = (newValue: SelectOptions | null) => {
    const user = { ...bookingUser };

    for (const prop in user) {
      user[prop] = newValue?.value[prop] || "";
    }

    setBookingUser(user);
  };

  const getSearchableUsers = async () => {
    const users: SearchableUser[] = [];

    const usersSnapshot = await getDocs(usersCollection);
    usersSnapshot.forEach((doc) =>
      users.push({ id: doc.id, ...doc.data() } as SearchableUser)
    );

    const bookingsSnapshot = await getDocs(bookingCollection);
    bookingsSnapshot.forEach((doc) => {
      if (doc.data().firstName || doc.data().companyName) {
        users.push({ id: doc.id, ...doc.data() } as SearchableUser);
      }
    });

    setSearchableUsers(
      users
        .filter(
          (el, index, self) =>
            index === self.findIndex((elFind) => el.email === elFind.email)
        )
        .map((el) => ({
          value: el,
          label: `${el.firstName} ${el.surname} ${
            el.companyName ? "- " + el.companyName : ""
          }`,
        }))
    );
  };

  useEffect(() => {
    getSearchableUsers();
  }, []);

  const navigate = useNavigate();

  const handleRemoveClick = (date: any) => {
    console.log(date);

    openModal("remove_hours", {
      date: date.date,
      start_hour: date.start_time,
      hours: date.hours,
    });
  };

  const getPriceByHour = (type: string, hours: any) => {
    let basicPrice = 0;

    if (type === "standard") {
      basicPrice = STANDARD_PRICE;
    } else if (type === "evening") {
      basicPrice = EVENING_PRICE;
    } else {
      basicPrice = WEEKEND_PRICE;
    }

    if (Math.floor(hours.length / 10) <= 2) {
      return basicPrice - Math.floor(hours.length / 10);
    } else if (hours.length < 40) {
      return basicPrice - 3;
    }

    return basicPrice - 4;
  };

  const getHoursByType = (type: string) => {
    if (type === "standard") {
      return hours.filter(
        (hour) =>
          getNumericHour(hour.start_time) < 17 &&
          [0, 6].indexOf(new Date(hour.date).getDay()) === -1
      );
    } else if (type === "evening") {
      return hours.filter(
        (hour) =>
          getNumericHour(hour.start_time) >= 17 &&
          [0, 6].indexOf(new Date(hour.date).getDay()) === -1
      );
    }

    return hours.filter(
      (hour) => [0, 6].indexOf(new Date(hour.date).getDay()) !== -1
    );
  };

  const sumPrice = (type: string) => {
    const hoursByType = getHoursByType(type);
    const hoursAmount = hoursByType.length;

    const pricePerHour = getPriceByHour(type, hoursByType);

    return pricePerHour * hoursAmount;
  };

  const getHourType = (date: string, time: string) => {
    const checkDate = new Date(`${date} ${time}`);

    if (checkDate.getDay() === 0 || checkDate.getDay() === 6) {
      return "weekend";
    } else if (checkDate.getHours() >= 17) {
      return "evening";
    }
    return "standard";
  };

  const checkNotFreeHours = async () => {
    const notFreeHours = [];
    for (let i = 0; i < hours.length; i++) {
      const fbHour = await getDocs(
        query(
          bookingCollection,
          where("date", "==", hours[i].date),
          where("start_time", "==", hours[i].start_time)
        )
      );

      if (fbHour.docs.length) {
        notFreeHours.push({ ...hours[i] });
        deleteHourFromBasket(hours[i].date, hours[i].start_time);
      }
    }

    if (notFreeHours.length) {
      openModal("not_free_hours", {
        hours: notFreeHours,
      });

      return true;
    }

    return false;
  };

  const saveBookingAsAdmin = async () => {
    if (await checkNotFreeHours()) {
      return;
    }

    const bookUser = { ...bookingUser };

    const fbPayment = await addDoc(paymentsCollection, {
      stripe_id: "",
      user_id: user?.id,
      amount:
        sumPrice("standard") +
        sumPrice("evening") +
        sumPrice("weekend") +
        getFlexPrice(),
      flexible_hours: getFlexAmount(),
      status: "manual",
      date: new Date(),
      ...bookUser,
    });

    for (let i = 0; i < hours.length; i++) {
      if (hours[i].date === "flexible") {
        continue;
      }

      const hourType = getHourType(hours[i].date, hours[i].start_time);

      await addDoc(bookingCollection, {
        ...hours[i],
        datetype: new Date(`${hours[i].date} ${hours[i].start_time}`),
        user_id: user?.id,
        type: hourType,
        price: getPriceByHour(hourType, getHoursByType(hourType)),
        payment_id: fbPayment.id,
        ...bookUser,
      });
    }

    localStorage.setItem("clear_basket", "true");

    window.location.reload();
  };

  const saveHoursToDB = async (payment_id: string, bookingUser: any) => {
    for (let i = 0; i < hours.length; i++) {
      if (hours[i].date === "flexible") {
        continue;
      }

      const hourType = getHourType(hours[i].date, hours[i].start_time);

      await addDoc(bookingCollection, {
        ...hours[i],
        datetype: new Date(`${hours[i].date} ${hours[i].start_time}`),
        user_id: user?.id,
        type: hourType,
        price: getPriceByHour(hourType, getHoursByType(hourType)),
        payment_id,
        ...bookingUser,
      });
    }
  };

  const validatePaymentUserInfo = () => {
    let isCorrect = true;
    let errors: any = {};

    for (let i = 0; i < adminBookingFormElement.length; i++) {
      if (adminBookingFormElement[i].inputName === "companyName") {
        continue;
      }
      if (!bookingUser[adminBookingFormElement[i].inputName]) {
        isCorrect = false;
        errors[adminBookingFormElement[i].inputName] = true;
      }
    }

    setBookingUserErrors(errors);
    return isCorrect;
  };

  const getFlexAmount = () =>
    hours
      .filter((el) => el.date === "flexible")
      .reduce((acc, el) => acc + (el.hours ? el.hours : 0), 0);

  const getFlexPrice = () =>
    hours
      .filter((el) => el.date === "flexible")
      .reduce((acc, el) => acc + (el.hours ? el.hours : 0), 0) * FLEX_PRICE;

  return (
    <BasketWrapper>
      {!hours.length && (
        <EmptyBasketInfo>
          Your basket is empty, go to{" "}
          <Button bgColor="#e0e8f6" color="#000" onClick={() => navigate("/")}>
            Bookings
          </Button>
        </EmptyBasketInfo>
      )}
      {hours.length > 0 && (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Date</b>
                  </TableCell>
                  <TableCell>
                    <b>Time</b>
                  </TableCell>
                  <TableCell>
                    <b>Hours</b>
                  </TableCell>
                  <TableCell>
                    <b>Price</b>
                  </TableCell>
                  <TableCell>
                    <b>Remove</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hours
                  .sort(
                    (a, b) =>
                      new Date(a.date + " " + a.start_time).getTime() -
                      new Date(b.date + " " + b.start_time).getTime()
                  )
                  .map((hour) => (
                    <TableRow>
                      <TableCell>
                        {hour.date === "flexible" && <>Flexible</>}
                        {hour.date !== "flexible" && (
                          <>
                            {hour.date} ({getDayName(hour.date)})
                          </>
                        )}
                      </TableCell>
                      <TableCell>
                        {hour.date === "flexible" && <>Flexible</>}
                        {hour.date !== "flexible" && (
                          <>
                            {" "}
                            {getNumericHour(hour.start_time) > 12
                              ? getNumericHour(hour.start_time) - 12
                              : getNumericHour(hour.start_time)}
                            :00{" "}
                            {getNumericHour(hour.start_time) < 12 ? "am" : "pm"}
                            {" - "}
                            {getNumericHour(hour.end_time) > 12
                              ? getNumericHour(hour.end_time) - 12
                              : getNumericHour(hour.end_time)}
                            :00{" "}
                            {getNumericHour(hour.end_time) < 12 ? "am" : "pm"}
                          </>
                        )}
                      </TableCell>
                      <TableCell>
                        {hour.date === "flexible" && <>{hour.hours}</>}
                        {hour.date !== "flexible" && <>1</>}
                      </TableCell>
                      <TableCell>
                        £{" "}
                        {hour.date === "flexible" && hour.hours && (
                          <>{hour.hours * FLEX_PRICE}</>
                        )}
                        {hour.date !== "flexible" && (
                          <>
                            {" "}
                            {getPriceByHour(
                              getHourType(hour.date, hour.start_time),
                              getHoursByType(
                                getHourType(hour.date, hour.start_time)
                              )
                            )}
                          </>
                        )}
                      </TableCell>
                      <TableCell>
                        <Button
                          bgColor="#e0e8f6"
                          color="#000"
                          onClick={() => handleRemoveClick(hour)}
                        >
                          Remove
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <PaymentDetailsWrapper>
            <PaymentDetailsItem>
              Standard hours: {getHoursByType("standard").length} (subtotal £{" "}
              {sumPrice("standard")})
            </PaymentDetailsItem>
            <PaymentDetailsItem>
              Evening hours: {getHoursByType("evening").length} (subtotal £{" "}
              {sumPrice("evening")})
            </PaymentDetailsItem>
            <PaymentDetailsItem>
              Weekend hours: {getHoursByType("weekend").length} (subtotal £{" "}
              {sumPrice("weekend")})
            </PaymentDetailsItem>
            <PaymentDetailsItem>
              Flexible hours: {getFlexAmount()} (subtotal £ {getFlexPrice()})
            </PaymentDetailsItem>
            <hr />
            <PaymentDetailsItemTotal>
              Total: £{" "}
              {sumPrice("standard") +
                sumPrice("evening") +
                sumPrice("weekend") +
                getFlexPrice()}
            </PaymentDetailsItemTotal>
          </PaymentDetailsWrapper>

          {user?.id !== "DkBOnsN5oB80iFNczQTF" && (
            <>
              <PaymentDetailsItemTotal style={{ marginTop: "40px" }}>
                Invoice details
              </PaymentDetailsItemTotal>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: "20px",
                  marginBottom: "40px",
                }}
              >
                {adminBookingFormElement.map((el, index) => (
                  <div>
                    <Label>{el.labelContent}</Label>
                    <Input
                      style={{
                        borderColor: bookingUserErrors[el.inputName]
                          ? "red"
                          : "lightgray",
                      }}
                      key={index}
                      name={el.inputName}
                      type={el.inputType}
                      value={bookingUser[el.inputName]}
                      onChange={(e) =>
                        setBookingUser((prev) => ({
                          ...prev,
                          [el.inputName]: e.target.value,
                        }))
                      }
                    />
                  </div>
                ))}
              </div>

              <Elements
                stripe={stripePromise}
                options={{
                  mode: "payment",
                  amount: 200,
                  currency: "gbp",
                }}
              >
                <PaymentDetailsItemTotal style={{ marginTop: "40px" }}>
                  Payment methods
                </PaymentDetailsItemTotal>
                <InjectedCheckoutForm
                  checkNotFreeHours={checkNotFreeHours}
                  user={user}
                  amount={
                    sumPrice("standard") +
                    sumPrice("evening") +
                    sumPrice("weekend") +
                    getFlexPrice()
                  }
                  flexibleHoursAmount={getFlexAmount()}
                  saveHoursToDB={saveHoursToDB}
                  validatePaymentUserInfo={validatePaymentUserInfo}
                  bookingUser={bookingUser}
                />
              </Elements>
            </>
          )}
          {user?.id === "DkBOnsN5oB80iFNczQTF" && (
            <>
              <div style={{ marginBottom: "20px", width: "350px" }}>
                <Select options={searchbleUsers} onChange={handleSelectUser} />
              </div>

              {adminBookingFormElement.map((el, index) => (
                <div>
                  <Label>{el.labelContent}</Label>
                  <Input
                    key={index}
                    name={el.inputName}
                    type={el.inputType}
                    value={bookingUser[el.inputName]}
                    onChange={(e) =>
                      setBookingUser((prev) => ({
                        ...prev,
                        [el.inputName]: e.target.value,
                      }))
                    }
                  />
                </div>
              ))}
              <div style={{ marginTop: "40px" }}>
                <Button onClick={saveBookingAsAdmin}>Book</Button>
              </div>
            </>
          )}
        </>
      )}
    </BasketWrapper>
  );
};

export default Basket;
