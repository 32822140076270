import { BookServiceWrapper } from "./StyledBookService";
import BookingCalendar from "../../components/atoms/BookingCalendar";

export interface ReservationInterface {
  id?: string;
  title: string;
  start: Date;
  end: Date;
  user_id: string | undefined;
}

const BookService = () => {
  return (
    <BookServiceWrapper>
      <BookingCalendar logged={false} />
    </BookServiceWrapper>
  );
};

export default BookService;
