import {
  MenuWrapper,
  MenuLinks,
  MenuLinkItem,
  MenuLink,
  AuthButtonsWrapper,
  BasketLink,
} from "./StyledMenu";
import Button from "../atoms/Button";
import { useUser } from "../../contexts/User";
import { FaBasketShopping } from "react-icons/fa6";
import { useBasket } from "../../contexts/Basket";
import { Badge } from "@mui/material";
import { IoPerson } from "react-icons/io5";
import { NavLink } from "react-router-dom";

const LoggedMenu = () => {
  const { logout } = useUser();

  const { hours } = useBasket();

  return (
    <MenuWrapper>
      <nav>
        <MenuLinks>
          <MenuLinkItem>
            <MenuLink to="/">Booking</MenuLink>
          </MenuLinkItem>
          <MenuLinkItem>
            <MenuLink to="/services">Services</MenuLink>
          </MenuLinkItem>
          <MenuLinkItem>
            <MenuLink to="/pricing">Pricing</MenuLink>
          </MenuLinkItem>
          <MenuLinkItem>
            <MenuLink to="/booking-history">History</MenuLink>
          </MenuLinkItem>
          <MenuLinkItem>
            <MenuLink to="/payments-history">Payments</MenuLink>
          </MenuLinkItem>
          <MenuLinkItem>
            <MenuLink to="/contact-us">Contact</MenuLink>
          </MenuLinkItem>
        </MenuLinks>
      </nav>
      <AuthButtonsWrapper>
        <BasketLink to="/basket">
          <Badge badgeContent={hours.length} color="primary">
            <FaBasketShopping color="action" />
          </Badge>
          <p>Basket</p>
        </BasketLink>
        <BasketLink to="/profile" title="My profile">
          <IoPerson />
        </BasketLink>
        <Button onClick={() => logout()}>Logout</Button>
      </AuthButtonsWrapper>
    </MenuWrapper>
  );
};

export default LoggedMenu;
