import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useBasket } from "../../../contexts/Basket";
import {
  BookButton,
  ButtonsWrapper,
  LoginInfoIcon,
  NoLoginInfoWrapper,
} from "./StyledBookingModal";
import { useUser } from "../../../contexts/User";
import Button from "../../atoms/Button";
import { useModal } from "../../../contexts/Modal";
import {
  bookingCollection,
  firestore,
  usersCollection,
} from "../../../firebase";
import { addDoc, deleteDoc, doc, getDoc, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";

const createHourArray = () => {
  const hours = [];
  for (let i = 7; i < 21; i++) {
    hours.push(i);
  }
  return hours;
};

interface PropsObject {
  date: Date;
  bookings: any[];
  logged: boolean;
}

interface Props {
  data: PropsObject;
}

const BookingModal = ({ data }: Props) => {
  const { addHourToBasket, isHourInBasket, deleteHourFromBasket } = useBasket();
  const { user } = useUser();

  const excludeHours = async (date: string, hour: number) => {
    const fullStartHour = (hour < 10 ? "0" + hour : hour) + ":00";
    const fullEndHour = (hour + 1 < 10 ? "0" + (hour + 1) : hour + 1) + ":00";

    const bookDate = new Date(date);
    const bookYear = bookDate.getFullYear();
    const bookMonth = bookDate.getMonth() + 1;
    const bookDay = bookDate.getDate();

    const book = {
      date: `${bookYear}-${bookMonth < 10 ? "0" + bookMonth : bookMonth}-${
        bookDay < 10 ? "0" + bookDay : bookDay
      }`,
      start_time: fullStartHour,
      end_time: fullEndHour,
    };

    await addDoc(bookingCollection, {
      ...book,
      datetype: new Date(`${date} ${hour < 9 ? "0" + hour : hour}:00`),
      user_id: user?.id,
      type: "excluded",
      price: 0,
      payment_id: "",
    });

    window.location.reload();
  };

  const getUserData = (hour: any) => {
    if (hour.contactNumber) {
      return hour;
    }
  };

  const { closeModal, openModal } = useModal();

  const handleGoToCheckout = () => {
    window.location.href = "/basket";
  };

  const setHourAsAvailable = async (id: string) => {
    await deleteDoc(doc(firestore, "bookings", id));
    window.location.reload();
  };

  const openDetailsModal = (type: any, user: any) => {
    closeModal();
    openModal("booking_details", { type, user });
  };

  const checkHoursInBookings = (hour: number) => {
    const stringHour = (hour < 10 ? `0${hour}` : hour) + ":00";
    const findHour = data.bookings.find((el) => el.start_time === stringHour);

    if (!findHour) {
      return {
        type: "empty",
      };
    }

    return {
      type: findHour.user_id === user?.id ? "user" : "other",
      id: findHour.id,
      hour: findHour,
    };
  };

  const renderAvailabilityForNotLogged = (hour: number) => {
    return (
      <>
        {checkHoursInBookings(hour).type === "empty" ? (
          <div>Avaliable</div>
        ) : (
          <div style={{ textDecoration: "line-through" }}>Unavailable</div>
        )}
      </>
    );
  };

  const renderAvailabilityForLoggedUser = (hour: number) => {
    const reserveHour = checkHoursInBookings(hour);

    if (reserveHour.type === "empty") {
      if (isHourInBasket(data.date.toDateString(), hour.toString())) {
        return (
          <BookButton
            onClick={(e) =>
              deleteHourFromBasket(data.date.toDateString(), hour.toString())
            }
          >
            Cancel
          </BookButton>
        );
      } else {
        return (
          <BookButton
            onClick={(e) =>
              addHourToBasket(data.date.toDateString(), hour.toString())
            }
          >
            Book
          </BookButton>
        );
      }
    } else if (
      reserveHour.type === "user" &&
      user?.id !== "DkBOnsN5oB80iFNczQTF"
    ) {
      return <div>Your booking</div>;
    } else if (reserveHour.type === "other") {
      return <div style={{ textDecoration: "line-through" }}>Unavailable</div>;
    }
  };

  const renderAvaliblityForAdmin = (hour: number) => {
    const type = checkHoursInBookings(hour);

    if (type.type === "empty") {
      if (isHourInBasket(data.date.toDateString(), hour.toString())) {
        return (
          <BookButton
            onClick={(e) =>
              deleteHourFromBasket(data.date.toDateString(), hour.toString())
            }
          >
            Cancel
          </BookButton>
        );
      } else {
        return (
          <>
            <BookButton
              onClick={(e) =>
                addHourToBasket(data.date.toDateString(), hour.toString())
              }
            >
              Book
            </BookButton>
            <BookButton
              onClick={(e) => excludeHours(data.date.toDateString(), hour)}
            >
              Exclude
            </BookButton>
          </>
        );
      }
    } else if (type.type === "user" && type.hour.type === "excluded") {
      return (
        <BookButton
          onClick={(e) => setHourAsAvailable(checkHoursInBookings(hour).id)}
        >
          Available
        </BookButton>
      );
    } else if (
      (type.type === "user" && type.hour.type !== "excluded") ||
      type.type === "other"
    ) {
      const bookedUser = getUserData(checkHoursInBookings(hour).hour);

      return (
        <p
          style={{ cursor: "pointer" }}
          onClick={() => openDetailsModal(type.type, bookedUser)}
        >
          {bookedUser?.firstName} {bookedUser?.surname}{" "}
          {bookedUser?.companyName}
        </p>
      );
    }
  };

  return (
    <>
      {!data.logged && (
        <NoLoginInfoWrapper>
          <LoginInfoIcon />
          Please Login/ Register to book hours <br /> or contact us for help
        </NoLoginInfoWrapper>
      )}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Time range</TableCell>
              <TableCell>Availability</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {createHourArray().map((hour) => (
              <TableRow>
                <TableCell
                  style={{
                    textDecoration:
                      checkHoursInBookings(hour).type === "other"
                        ? "line-through"
                        : "none",
                  }}
                >
                  {hour > 12 ? hour - 12 : hour}:00 {hour < 12 ? "am" : "pm"} -{" "}
                  {hour > 11 ? hour - 11 : hour + 1}:00{" "}
                  {hour < 11 ? "am" : "pm"}{" "}
                </TableCell>
                <TableCell>
                  {!data.logged && renderAvailabilityForNotLogged(hour)}
                  {data.logged &&
                    user?.id !== "DkBOnsN5oB80iFNczQTF" &&
                    renderAvailabilityForLoggedUser(hour)}
                  {data.logged &&
                    user?.id === "DkBOnsN5oB80iFNczQTF" &&
                    renderAvaliblityForAdmin(hour)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {data.logged && (
        <ButtonsWrapper>
          <Button onClick={handleGoToCheckout}>Checkout</Button>
          <Button bgColor="#e0e8f6" color="#000" onClick={() => closeModal()}>
            Back
          </Button>
        </ButtonsWrapper>
      )}
    </>
  );
};

export default BookingModal;
