import Contact from "../../components/forms/Contact";
import {
  ContactPageWrapper,
  ContactText,
  ContactImage,
  ContactTextHeader,
  ContactTextDescription,
  ContactTextIcon,
  ContactItemData,
  ContactPhoneIcon,
  ContactEmailIcon,
  ContactFbIcon,
  ContactInstagramIcon,
  ContactTiktokIcon,
  ContactTwoItems,
  ContactLinkedInIcon,
} from "./ContactUsStyled";
import contactUsImage from "../../assets/images/contact-us.jpg";
import instagramImage from "../../assets/images/insta_logo.png";
import tiktokImage from "../../assets/images/tiktok_logo.png";

const ContactUs = () => {
  return (
    <ContactPageWrapper>
      <ContactText>
        <ContactImage src={contactUsImage} />
        <ContactTextHeader>Feel free to request a call back!</ContactTextHeader>
        <ContactTextDescription>
          Instead of submitting this form, it can be faster to get in contact
          via our <br /> WhatsApp <ContactTextIcon /> +44 7999 120260.
        </ContactTextDescription>
        <ContactTwoItems>
          <ContactItemData>
            <ContactPhoneIcon />
            <a href="tel: +44 7999120260">+44 7999 120260</a>
          </ContactItemData>
          <ContactItemData>
            <ContactEmailIcon />
            <a href="mailto:swiftbsltd@gmail.com">swiftbsltd@gmail.com</a>
          </ContactItemData>
          <ContactItemData>
            <ContactFbIcon />
            <a
              href="https://www.facebook.com/profile.php?id=61561679484206"
              target="_blank"
            >
              Swift Business
            </a>
          </ContactItemData>
          <ContactItemData>
            <ContactInstagramIcon src={instagramImage} />
            <a
              href="https://www.instagram.com/swiftbusinesssupportltd?igsh=MWdwOW9xd2d3NGxhaw=="
              target="_blank"
            >
              swiftbusinesssupportltd
            </a>
          </ContactItemData>
          <ContactItemData>
            <ContactTiktokIcon src={tiktokImage} />
            <a
              href="https://www.tiktok.com/@swift.business.su?_t=8o5xPAE657g&_r=1"
              target="_blank"
            >
              swift.business.su
            </a>
          </ContactItemData>
          <ContactItemData>
            <ContactLinkedInIcon />
            <a
              href="https://www.linkedin.com/in/swift-business-support-ltd-93b37031a"
              target="_blank"
            >
              Swift Business Support Ltd
            </a>
          </ContactItemData>
        </ContactTwoItems>
      </ContactText>
      <Contact />
    </ContactPageWrapper>
  );
};

export default ContactUs;
