import { useState, useEffect } from "react";
import { getDoc, doc, getDocs, where, query } from "firebase/firestore";
import { bookingCollection, firestore } from "../../firebase";
import { useParams } from "react-router-dom";
import { usePDF } from "react-to-pdf";
import logo from "../../assets/images/logoSBSnoBG.png";
import sbsText from "../../assets/images/sbstext.png";
import Button from "../../components/atoms/Button";

const Invoice = () => {
  const { toPDF, targetRef } = usePDF({ filename: "invoice.pdf" });

  const { id } = useParams();

  const [payment, setPayment] = useState(null);
  const [bookings, setBookings] = useState([]);

  const getPayment = async () => {
    setPayment((await getDoc(doc(firestore, "payments", id))).data());
  };

  const getBookings = async () => {
    const bookingSnapshot = await getDocs(
      query(bookingCollection, where("payment_id", "==", id))
    );

    setBookings(
      bookingSnapshot.docs.map((booking) => ({
        id: booking.id,
        ...booking.data(),
      }))
    );
  };

  useEffect(() => {
    getPayment();
    getBookings();
  }, []);

  const standardHours = bookings.filter((el) => el.type === "standard");
  const standardHoursPrice = standardHours.reduce(
    (acc, el) => acc + parseInt(el.price),
    0
  );

  const eveningHours = bookings.filter((el) => el.type === "evening");
  const eveningHoursPrice = eveningHours.reduce(
    (acc, el) => acc + parseInt(el.price),
    0
  );

  const weekendHours = bookings.filter((el) => el.type === "weekend");
  const weekendHoursPrice = weekendHours.reduce(
    (acc, el) => acc + parseInt(el.price),
    0
  );

  if (!payment) {
    return null;
  }

  return (
    <div style={{ width: "60%", margin: "50px auto 0 auto" }}>
      <Button onClick={() => toPDF()}>Download</Button>
      <div
        ref={targetRef}
        style={{
          border: "1px solid #e3e3e3",
          margin: "50px 0",
          padding: "25px 0",
        }}
      >
        <table style={{ width: "90%", margin: "0 auto 25px auto" }}>
          <tbody>
            <tr>
              <td>
                <img width={130} src={logo} alt="Invoice logo" />
              </td>
              <td style={{ padding: 20 }}>
                <ul style={{ textAlign: "right", listStyle: "none" }}>
                  <li
                    style={{
                      fontFamily: '"Workbench", sans-serif',
                      fontSize: 23,
                    }}
                  >
                    <img src={sbsText} style={{ width: 350 }} />
                  </li>
                  <li style={{ marginBottom: "5px" }}>United Kingdom</li>
                  <li style={{ marginBottom: "5px" }}>
                    Mobile No (44)7999120260
                  </li>
                  <li style={{ marginBottom: "5px" }}>
                    Email{" "}
                    <a
                      style={{ color: "#000", textDecoration: "none" }}
                      href="mailto:swiftbsltd@gmail.com"
                    >
                      swiftbsltd@gmail.com
                    </a>
                  </li>
                  <li>Company Number 15761049</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <table style={{ width: "90%", margin: "0 auto 25px auto" }}>
          <tbody>
            <tr>
              <td>
                <ul
                  style={{
                    listStyle: "none",
                    backgroundColor: "#f5f5f5",
                    padding: 20,
                    border: "1px solid #e3e3e3",
                    borderRadius: 4,
                  }}
                >
                  <li style={{ marginBottom: "5px" }}>
                    <strong>Invoice: </strong>
                    {payment.invoice_number}
                  </li>
                  <li style={{ marginBottom: "5px" }}>
                    <strong>Invoice Date:</strong>
                    {new Date(payment.date.seconds * 1000).toLocaleDateString()}
                  </li>
                  <li>
                    <strong>Status:</strong>
                    <span
                      style={{
                        display: "inline",
                        padding: ".2em .6em .3em",
                        fontSize: "75%",
                        fontWeight: 700,
                        lineHeight: 1,
                        color: "#fff",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                        verticalAlign: "baseline",
                        borderRadius: ".25em",
                        backgroundColor: "#5cb85c",
                        marginLeft: "5px",
                      }}
                    >
                      PAID
                    </span>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <table style={{ width: "90%", margin: "0 auto 50px auto" }}>
          <tbody>
            <tr>
              <td>
                <b>Invoiced To</b> <br />
                {payment.firstName + " " + payment.surname}
                <br />
                {payment.street}
                <br />
                {payment.town}
                <br />
                {payment.postcode}
                <br />
                {payment.companyName && (
                  <>
                    {payment.companyName} <br />
                  </>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <table
          style={{
            width: "90%",
            margin: "0 auto",
            border: "1px solid #ddd",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid #ddd",
                  textAlign: "center",
                  width: "70%",
                  padding: 10,
                }}
              >
                Description
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  textAlign: "center",
                  width: "5%",
                  padding: 10,
                }}
              >
                Qty
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  textAlign: "center",
                  width: "25%",
                  padding: 10,
                }}
              >
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ border: "1px solid #ddd", padding: 10 }}>
                Standard hours
              </td>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: 10,
                  textAlign: "center",
                }}
              >
                {standardHours.length}
              </td>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: 10,
                  textAlign: "center",
                }}
              >
                £ {standardHoursPrice}
              </td>
            </tr>
            <tr>
              <td style={{ border: "1px solid #ddd", padding: 10 }}>
                Evening hours
              </td>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: 10,
                  textAlign: "center",
                }}
              >
                {eveningHours.length}
              </td>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: 10,
                  textAlign: "center",
                }}
              >
                £ {eveningHoursPrice}
              </td>
            </tr>
            <tr>
              <td style={{ border: "1px solid #ddd", padding: 10 }}>
                Weekend hours
              </td>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: 10,
                  textAlign: "center",
                }}
              >
                {weekendHours.length}
              </td>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: 10,
                  textAlign: "center",
                }}
              >
                £ {weekendHoursPrice}
              </td>
            </tr>
            <tr>
              <td style={{ border: "1px solid #ddd", padding: 10 }}>
                Flexible hours
              </td>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: 10,
                  textAlign: "center",
                }}
              >
                {payment.flexible_hours}
              </td>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: 10,
                  textAlign: "center",
                }}
              >
                £{" "}
                {payment.amount -
                  standardHoursPrice -
                  eveningHoursPrice -
                  weekendHoursPrice}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th
                colSpan={2}
                style={{
                  border: "1px solid #ddd",
                  padding: 10,
                  textAlign: "right",
                }}
              >
                Sub Total:
              </th>
              <th style={{ border: "1px solid #ddd", textAlign: "center" }}>
                £ {payment.amount}
              </th>
            </tr>
            <tr>
              <th
                colSpan={2}
                style={{
                  border: "1px solid #ddd",
                  padding: 10,
                  textAlign: "right",
                }}
              >
                0% VAT:
              </th>
              <th style={{ border: "1px solid #ddd", textAlign: "center" }}>
                £ 0
              </th>
            </tr>
            <tr>
              <th
                colSpan={2}
                style={{
                  border: "1px solid #ddd",
                  padding: 10,
                  textAlign: "right",
                }}
              >
                Total:
              </th>
              <th style={{ border: "1px solid #ddd", textAlign: "center" }}>
                <p>£ {payment.amount}</p>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default Invoice;
