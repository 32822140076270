import styled from "styled-components";

export const BasketWrapper = styled.div`
  padding: 20px 100px;

  @media (max-width: 900px) {
    padding: 20px;
  }
`;

export const PaymentDetailsWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
`;
export const PaymentDetailsItem = styled.p`
  margin-bottom: 15px;
  color: #3c3744;
`;

export const PaymentDetailsItemTotal = styled.p`
  margin: 15px 0;
  font-size: 20px;
  font-weight: bold;
  color: #3c3744;
`;

export const EmptyBasketInfo = styled.div`
  padding: 100px 0;
  font-size: 30px;
  text-align: center;
  font-weight: bold;
`;

export const BasketSpinnerWrapper = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 50px;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  svg {
    animation-name: spin;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-duration: 2000ms;
  }
`;

export const Input = styled.input`
  width: 350px;
  margin: 6px 0;
  padding: 10px;
  border: 2px solid lightgray;
  border-radius: 5px;

  @media (max-width: 900px) {
    width: 70vw;
  }
`;

export const Label = styled.label`
  color: #3c3744;
  font-weight: 500;
  display: block;
`;
