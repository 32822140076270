import styled from "styled-components";

interface StyledButtonProps {
  bgColor: string;
  color: string;
}

export const StyledButton = styled.button<StyledButtonProps>`
  cursor: pointer;
  border: none;
  outline: none;
  width: 150px;
  border-radius: 20px;
  color: ${(props) => props.color};
  background-color: ${(props) => props.bgColor};
  padding: 10px 20px;
  font-size: 15px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
