import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

interface ModalInterface {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  modalComponent: string;
  setModalComponent: Dispatch<SetStateAction<string>>;
  data: any;
  setData: Dispatch<SetStateAction<any>>;
}

const ModalContext = createContext<ModalInterface>({
  isModalOpen: false,
  setIsModalOpen: () => {},
  modalComponent: "",
  setModalComponent: () => {},
  data: null,
  setData: () => {},
});

interface ModalProviderProps {
  children: JSX.Element;
}

const ModalProvider = ({ children }: ModalProviderProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalComponent, setModalComponent] = useState<string>("");
  const [data, setData] = useState(null);

  return (
    <ModalContext.Provider
      value={{
        isModalOpen,
        setIsModalOpen,
        modalComponent,
        setModalComponent,
        data,
        setData,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const {
    isModalOpen,
    setIsModalOpen,
    modalComponent,
    setModalComponent,
    setData,
    data,
  }: ModalInterface = useContext(ModalContext);

  const openModal = (modalComponent: string, modalData?: any) => {
    setIsModalOpen(true);
    setModalComponent(modalComponent);
    if (modalData) {
      setData(modalData);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalComponent("");
    setData(null);
  };

  return { isModalOpen, openModal, closeModal, modalComponent, data };
};

export default ModalProvider;
