import {
  ServicesWrapper,
  ServiceItem,
  ServiceImage,
  ServiceTitle,
  ServiceList,
  ServiceListItem,
  ServiceListItemIcon,
  ServiceListItemText,
} from "./StyledServices";
import BKandPAYImage from "../../assets/images/BK_PAY.jpg";
import PAandAdminImage from "../../assets/images/PA_Admin.jpg";
import AdHocImage from "../../assets/images/diary.jpg";
import homeImage from "../../assets/images/homepage_background.jpg";
import itSupport from "../../assets/images/it_support.jpg";
import bookkeeping from "../../assets/images/bookkiping.jpg";

const Services = () => {
  return (
    <ServicesWrapper>
      <ServiceItem>
        <ServiceImage>
          <img src={bookkeeping} />
        </ServiceImage>
        <div>
          <ServiceTitle>Bookkeeping</ServiceTitle>
          <ServiceList>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>Data entry</ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>Credit control</ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>VAT returns</ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>
                Bank, paypal and credit card reconciliations
              </ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>
                liaising with clients to resolve client queries
              </ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>raising Sales invoices</ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>Sales ledger conrol</ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>Purchase ledger control</ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>Cash flow forecasting</ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>
                Accounts Payable, multi-currency payments{" "}
              </ServiceListItemText>
            </ServiceListItem>
          </ServiceList>
        </div>
      </ServiceItem>
      <ServiceItem>
        <ServiceImage>
          <img src={BKandPAYImage} />
        </ServiceImage>
        <div>
          <ServiceTitle>Payroll</ServiceTitle>
          <ServiceList>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>
                Payroll run to your current payment schedule
              </ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>
                Payslips and reports emailed to you for acceptance
              </ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>
                RTI submissions made directly to HMRC
              </ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>
                Starters and leavers including P45
              </ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>
                Annual holiday and duvet day entitlements
              </ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>
                Pensions (auto enrolment)
              </ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>
                Statutory payments for sickness and parenting pay
              </ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>Legal deductions</ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>
                Salary sacrifice schemes
              </ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>CIS returns </ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>
                P11s, P11Ds, P32s and year end submission
              </ServiceListItemText>
            </ServiceListItem>
          </ServiceList>
        </div>
      </ServiceItem>
      <ServiceItem>
        <ServiceImage>
          <img src={PAandAdminImage} />
        </ServiceImage>
        <div>
          <ServiceTitle>PA & Administration</ServiceTitle>
          <ServiceList>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>
                Email and diary management
              </ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>
                Website and blog updates
              </ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>Document formatting</ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>Project Management</ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>Sales Support</ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>
                Email and diary management
              </ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>General admin </ServiceListItemText>
            </ServiceListItem>
          </ServiceList>
        </div>
      </ServiceItem>
      <ServiceItem>
        <ServiceImage>
          <img src={homeImage} />
        </ServiceImage>
        <div>
          <ServiceTitle>HR Management</ServiceTitle>
          <ServiceList>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>Recruitment</ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>HR Management</ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>
                Dedicated support and coordination
              </ServiceListItemText>
            </ServiceListItem>
          </ServiceList>
        </div>
      </ServiceItem>
      <ServiceItem>
        <ServiceImage>
          <img src={itSupport} />
        </ServiceImage>
        <div>
          <ServiceTitle>IT Support</ServiceTitle>
          <ServiceList>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>
                Software development (React JS)
              </ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>Websites</ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>
                Database and CRM management
              </ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>
                Microsoft Office - Excel
              </ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>
                PowerPoint presentations
              </ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>Canva designs and edits</ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>Image editing</ServiceListItemText>
            </ServiceListItem>

            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>Systems setup</ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>
                Systems setup and customer support
              </ServiceListItemText>
            </ServiceListItem>
          </ServiceList>
        </div>
      </ServiceItem>
      <ServiceItem>
        <ServiceImage>
          <img src={AdHocImage} />
        </ServiceImage>
        <div>
          <ServiceTitle>Ad-hoc support</ServiceTitle>
          <ServiceList>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>Social Media Management</ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>
                Event planning and support
              </ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>
                Travel planning and itineraries
              </ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>Email marketing</ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>Social media support</ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>Personal support</ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>
                Diary, email and calendar management
              </ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>
                Spreadsheet creation and updating
              </ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>Research and reports</ServiceListItemText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListItemIcon />
              <ServiceListItemText>
                Meeting arrangements and reservations
              </ServiceListItemText>
            </ServiceListItem>
          </ServiceList>
        </div>
      </ServiceItem>
    </ServicesWrapper>
  );
};

export default Services;
