import styled from "styled-components";

export const InfoText = styled.div`
  margin-bottom: 20px;
`;

export const LineThroughtItem = styled.span`
  text-decoration: line-through;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 20px;
`;
