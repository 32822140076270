import styled from "styled-components";
import { BsInfoCircle } from "react-icons/bs";

export const BookButton = styled.button`
  background: #e0e8f6;
  color: #000;
  border: none;
  border-radius: 15px;
  padding: 5px 10px;
  cursor: pointer;
  margin-right: 5px;
`;

export const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;
`;

export const NoLoginInfoWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  background-color: #e0e8f6;
  padding: 20px 10px;
  line-height: 24px;
`;

export const LoginInfoIcon = styled(BsInfoCircle)`
  font-size: 25px;
`;
