import UnloggedMenu from "../../menus/UnloggedMenu";
import {
  HeaderWrapper,
  Logo,
  HeaderImgWrapper,
  HeaderLink,
  LinksWrapper,
  IconWrapper,
  PageTitle,
} from "./StyledHeader";
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import { useUser } from "../../../contexts/User";
import LoggedMenu from "../../menus/LoggedMenu";
import logo from "../../../assets/images/logoSBSnoBG.png";
import headerImg from "../../../assets/images/clock-money-tree.jpg";

const Header = () => {
  const { isLogged } = useUser();

  return (
    <>
      <HeaderWrapper>
        <Logo src={logo} />
        <PageTitle>
          <span>
            <b>S</b>wift
          </span>{" "}
          <span>
            <b>B</b>usiness
          </span>{" "}
          <span>
            <b>S</b>upport
          </span>{" "}
          <span>Ltd</span>
        </PageTitle>
        <LinksWrapper>
          <HeaderLink href="tel:123123123">
            <IconWrapper>
              <FaPhoneAlt />
            </IconWrapper>
            00 44 (0) 7999 120 260
          </HeaderLink>
          <HeaderLink href="mailto:a@a.pl">
            <IconWrapper>
              <FaEnvelope />
            </IconWrapper>
            swiftbsltd@gmail.com
          </HeaderLink>
        </LinksWrapper>
      </HeaderWrapper>
      {isLogged ? <LoggedMenu /> : <UnloggedMenu />}
    </>
  );
};

export default Header;
