import { useModal } from "../../../contexts/Modal";
import { useBasket } from "../../../contexts/Basket";
import Button from "../Button";
import { InfoText, ButtonsWrapper } from "./StyledRemoveHoursModal";

interface PropsObject {
  date: string;
  start_hour: string;
  hours: number;
}

interface Props {
  data: PropsObject;
}

const RemoveHoursModal = ({ data }: Props) => {
  const { closeModal } = useModal();
  const { deleteHourFromBasket } = useBasket();

  const handleRemoveClick = () => {
    deleteHourFromBasket(data.date, data.start_hour, data.hours);
    closeModal();
  };

  return (
    <div>
      <InfoText>Are you sure you want to remove this booking?</InfoText>
      <ButtonsWrapper>
        <Button onClick={() => handleRemoveClick()}>Remove</Button>
        <Button onClick={() => closeModal()} bgColor="red" color="#fff">
          Cancel
        </Button>
      </ButtonsWrapper>
    </div>
  );
};

export default RemoveHoursModal;
