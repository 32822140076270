import styled from "styled-components";
import { FaCheck } from "react-icons/fa";

export const WeekDaysWrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-bottom: 10px;
`;

export const CalendarDaysWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;

export const DayWrapper = styled.div`
  height: 100px;
  border: 1px solid lightgrey;
  padding: 10px;
  cursor: pointer;
`;

export const UnavailableDayWrapper = styled.div`
  height: 100px;
  border: 1px solid lightgrey;
  padding: 10px;
  background: #e6e6ef;
  cursor: pointer;
`;

export const MonthsButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;
export const MonthName = styled.div`
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 20px;
  color: #3c3744;
`;

export const AvailableHoursWrapper = styled.div`
  margin-top: 6px;
  text-align: center;
`;

export const AvailableHoursData = styled.span`
  font-weight: bold;
  color: #4f9b5a;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

export const AvailableHoursIcon = styled(FaCheck)`
  font-size: 17px;
`;

export const DayOfMonth = styled.p`
  background: lightgrey;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`;

export const CalendarWrapper = styled.div`
  @media (max-width: 600px) {
    max-width: 600px;
    overflow: auto;
  }
`;
