import { Outlet } from "react-router-dom";
import Header from "../components/atoms/Header";
import Footer from "../components/Footer";

const Unlogged = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

export default Unlogged;
